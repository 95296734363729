import React, {
  createContext,
  useContext,
  useEffect,
  useState,
  ReactNode,
  useCallback,
} from "react";
import { useKindeAuth } from "@kinde-oss/kinde-auth-react";

interface AuthContextType {
  token: string;
  loading: boolean;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const { getToken, isLoading } = useKindeAuth();
  const [token, setToken] = useState<string>("");

  const fetchToken = useCallback(async () => {
    if (isLoading) {
      // Wait until Kinde is done loading
      return;
    }

    const newToken = await getToken();
    if (newToken) {
      setToken(newToken);
    }
  }, [getToken, isLoading]);

  useEffect(() => {
    fetchToken();
  }, [fetchToken, isLoading]);

  return (
    <AuthContext.Provider value={{ token, loading: isLoading }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = (): AuthContextType => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};
