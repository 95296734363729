import { Box } from "@mui/material";
import { AssessmentOSATS } from "../Assessments/AssessmentOSATS";
import { ModalWrapper } from "../ModalWrapper";
import { useState } from "react";

export function SupervisorView() {
  const [open, setOpen] = useState<boolean>(true);
  return (
    <ModalWrapper
      modalTitle=""
      open={open}
      setOpen={setOpen}
      children={(close) => <AssessmentOSATS />}
    />
  );
}
