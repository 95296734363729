import { useKindeAuth } from "@kinde-oss/kinde-auth-react";
import UserAdmin from "../UserAdmin/UserAdmin";
import NotFound from "../Pages/NotFound";

export default function UserAdminRoute() {
  const { getPermission } = useKindeAuth();

  if (
    getPermission("user:view").isGranted ||
    getPermission("user:edit").isGranted ||
    getPermission("user:create").isGranted ||
    getPermission("user:delete").isGranted
  ) {
    return <UserAdmin />;
  } else {
    return <NotFound />;
  }
}
