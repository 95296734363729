import { LockOutlined } from "@mui/icons-material";
import { Container, Box, Avatar, Button, useTheme } from "@mui/joy";
import { Typography, TextField } from "@mui/material";

import Logo from "./Logo";
import { useKindeAuth } from "@kinde-oss/kinde-auth-react";
import GoogleButton from "react-google-button";
import { useState } from "react";

function LoginForm() {
  const { login } = useKindeAuth();

  const [email, setEmail] = useState("");

  const theme = useTheme();

  return (
    <>
      <Container maxWidth="xs">
        <Box
          sx={{
            mt: 10,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            width: "100%",
          }}
        >
          <Logo />
          <Box
            sx={{
              mb: 5,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              width: "100%",
            }}
          >
            <Typography
              variant="h6"
              style={{ color: theme.palette.primary[700] }}
            >
              Log, Assess, Progress
            </Typography>
          </Box>
          <Avatar sx={{ m: 1, bgcolor: theme.palette.primary[700] }}>
            <LockOutlined style={{ color: theme.palette.primary[50] }} />
          </Avatar>
          <Typography
            variant="h5"
            style={{ color: theme.palette.primary[700] }}
          >
            Login
          </Typography>
          <Box sx={{ mt: 1, width: "80%" }}>
            <TextField
              margin="normal"
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoFocus
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <Box textAlign="center">
              <Button
                sx={{ mt: 1, mb: 5 }}
                style={{
                  color: theme.palette.primary[50],
                  backgroundColor: theme.palette.primary[700],
                  width: "100%",
                }}
                onClick={() =>
                  login({
                    authUrlParams: {
                      connection_id: "conn_018f08852e6d53c26de79f2f73ca99f6",
                      login_hint: String(email),
                    },
                  })
                }
              >
                Continue with email
              </Button>
            </Box>
          </Box>

          <GoogleButton
            style={{ width: "80%", borderRadius: "6px" }}
            onClick={() =>
              login({
                authUrlParams: {
                  connection_id: "conn_018f08852e6d739171975fc11946b301",
                },
              })
            }
          >
            Continue with Google
          </GoogleButton>
        </Box>
      </Container>
    </>
  );
}

export default LoginForm;
