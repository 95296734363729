import List from "@mui/joy/List";
import ListSubheader from "@mui/joy/ListSubheader";
import ListItem from "@mui/joy/ListItem";
import ListItemButton from "@mui/joy/ListItemButton";
import ListItemDecorator from "@mui/joy/ListItemDecorator";
import ListItemContent from "@mui/joy/ListItemContent";

import DashboardOutlinedIcon from "@mui/icons-material/DashboardOutlined";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import FormatListBulletedOutlinedIcon from "@mui/icons-material/FormatListBulletedOutlined";
import AdminPanelSettingsOutlinedIcon from "@mui/icons-material/AdminPanelSettingsOutlined";
import ViewStreamOutlinedIcon from "@mui/icons-material/ViewStreamOutlined";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import { SvgIconComponent } from "@mui/icons-material";
import { Link as RouterLink, useLocation } from "react-router-dom";

import { useKindeAuth } from "@kinde-oss/kinde-auth-react";
import { useState } from "react";
import { useTheme } from "@mui/joy";
export default function Navigation() {
  const { getPermission } = useKindeAuth();

  const [hoverStates, setHoverStates] = useState([false, false, false]);

  const handleMouseEnter = (index: number) => {
    const newHoverStates = hoverStates.map((state, idx) => idx === index);
    setHoverStates(newHoverStates);
  };

  const handleMouseLeave = (index: number) => {
    const newHoverStates = [...hoverStates];
    newHoverStates[index] = false;
    setHoverStates(newHoverStates);
  };

  const items: Array<{
    url: string;
    icon: SvgIconComponent;
    text: string;
  }> = [
    {
      url: "/",
      icon: DashboardOutlinedIcon,
      text: "Dashboard",
    },
    {
      url: "/newprocedure",
      icon: AddCircleOutlineOutlinedIcon,
      text: "Ny procedure",
    },
    {
      url: "/studentview",
      icon: ViewStreamOutlinedIcon,
      text: "Kursist",
    },
    {
      url: "/profile",
      icon: AccountCircleOutlinedIcon,
      text: "Profil",
    },
    {
      url: "/myprocedures",
      icon: FormatListBulletedOutlinedIcon,
      text: "Mine procedure",
    },
  ];

  //Check for user admin rights
  if (
    getPermission("user:view").isGranted ||
    getPermission("user:edit").isGranted ||
    getPermission("user:create").isGranted ||
    getPermission("user:delete").isGranted
  ) {
    items.push({
      url: "/useradmin",
      icon: AdminPanelSettingsOutlinedIcon,
      text: "Brugerstyring",
    });
  }

  //Check for user supervisor rights
  if (
    getPermission("procedure:view-others").isGranted &&
    getPermission("procedure:assess").isGranted
  ) {
    items.push({
      url: "/supervisor",
      icon: VisibilityOutlinedIcon,
      text: "Supervison",
    });
  }

  const location = useLocation();
  const theme = useTheme();

  return (
    <List size="sm" sx={{ "--ListItem-radius": "8px", "--List-gap": "4px" }}>
      <ListItem nested>
        <List aria-labelledby="nav-list-browse">
          {items.map((item, index) => (
            <ListItem key={index}>
              <ListItemButton
                to={item.url}
                component={RouterLink}
                style={{
                  color: hoverStates[index]
                    ? theme.palette.primary[700]
                    : theme.palette.primary[50],
                  backgroundColor: hoverStates[index]
                    ? theme.palette.primary[50]
                    : theme.palette.primary[700],
                  textDecorationLine:
                    location.pathname == item.url ? "underline" : undefined,
                  fontWeight:
                    location.pathname == item.url ? "bold" : undefined,
                }}
                onMouseEnter={() => handleMouseEnter(index)}
                onMouseLeave={() => handleMouseLeave(index)}
              >
                <ListItemDecorator>
                  <item.icon fontSize="small" />
                </ListItemDecorator>
                <ListItemContent>{item.text}</ListItemContent>
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </ListItem>
    </List>
  );
}
