import * as React from "react";
import IconButton from "@mui/joy/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import { Outlet } from "react-router-dom";
import Navigation from "./Navigation";
import LayoutHeader from "./LayoutHeader";
import { Box, Drawer, ModalClose, Sheet, useTheme } from "@mui/joy";
import ModeToggle from "./ModeToggle";
import { PowerSettingsNew } from "@mui/icons-material";
import SideNav from "./SideNav";
import { useKindeAuth } from "@kinde-oss/kinde-auth-react";
import Logo from "../Logo";

export default function Layout() {
  const [open, setOpen] = React.useState(false);
  const toggleDrawer = React.useCallback(() => {
    setOpen(!open);
  }, [open]);

  const { logout } = useKindeAuth();
  const theme = useTheme();

  return (
    <Box
      sx={[
        {
          width: "100vw",
          height: "100vh",
          display: "grid",
          gridTemplateColumns: {
            xs: "0px 1fr",
            md: "240px 1fr",
          },
          gridTemplateRows: "64px 1fr",
          gridTemplateAreas: "'header header' 'sidenav main'",
          overflow: "hidden",
        },
      ]}
    >
      <LayoutHeader
        sx={{
          gridArea: "header",
          display: "flex",
          alignItems: "center",
        }}
      >
        <IconButton
          onClick={toggleDrawer}
          sx={{
            display: {
              sm: "initial",
              md: "none",
            },
            marginRight: "36px",
          }}
        >
          <MenuIcon />
        </IconButton>
        <Box sx={{ display: "flex", alignItems: "center", flexGrow: 1 }}>
          <Logo sx={{ width: "220px" }} />
        </Box>
        <ModeToggle />
        <IconButton onClick={() => logout()}>
          <PowerSettingsNew />
        </IconButton>
      </LayoutHeader>

      <Drawer
        open={open}
        onClose={toggleDrawer}
        sx={{
          display: {
            sm: "initial",
            md: "none",
          },
          gridArea: "sidenav",
        }}
      >
        <ModalClose />
        <SideNav onClick={toggleDrawer}>
          <Navigation />
        </SideNav>
      </Drawer>

      <SideNav
        sx={{
          display: {
            sm: "none",
            md: "initial",
          },
          gridArea: "sidenav",
        }}
      >
        <Navigation />
      </SideNav>

      <Sheet
        sx={{
          gridArea: "main",
          flex: 1,
          maxHeight: "100%",
          maxWidth: "100%",
          overflow: "auto",
          padding: 2,
        }}
      >
        <Outlet />
      </Sheet>
    </Box>
  );
}
