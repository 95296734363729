import { useState, useEffect } from "react";
import { Box } from "@mui/joy";

interface ProcedureIconProps {
  src: string;
  size?: number;
  onClick?: () => void;
}

export function ProcedureIcon(props: ProcedureIconProps) {
  const { src, size = 92, onClick } = props;

  const [imageSrc, setImageSrc] = useState<string | null>(null);

  const resizeImage = (imageUrl: string) => {
    const img = new Image();
    img.src = imageUrl;

    img.onload = () => {
      const canvas = document.createElement("canvas");
      canvas.width = size;
      canvas.height = size;

      const ctx = canvas.getContext("2d");
      if (ctx) {
        ctx.drawImage(img, 0, 0, size, size);
        const resizedImageUrl = canvas.toDataURL("image/png");
        setImageSrc(resizedImageUrl);
      }
    };
  };

  useEffect(() => {
    resizeImage(src);
  }, [src, size]);

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      sx={{
        width: size,
        height: size,
        borderRadius: 8,
        boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
        cursor: onClick ? "pointer" : "default",
      }}
      onClick={onClick}
    >
      {imageSrc ? (
        <img
          src={imageSrc}
          alt="icon"
          style={{
            width: "90%",
            height: "90%",
          }}
        />
      ) : (
        <Box>Loading...</Box>
      )}
    </Box>
  );
}
