import { KindeProvider } from "@kinde-oss/kinde-auth-react";
import { Root } from "./Root";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { AuthProvider } from "./services/AuthProvider";

const queryClient = new QueryClient();

function App() {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <KindeProvider
        clientId="44ed5b0a12d649edb687eec07979f07a"
        domain="https://mediskill.kinde.com"
        redirectUri={process.env.REACT_APP_KINDE_REDIRECT_URI || ""}
        logoutUri={process.env.REACT_APP_KINDE_LOGOUT_URI}
      >
        <QueryClientProvider client={queryClient}>
          <AuthProvider>
            <Root />
          </AuthProvider>
        </QueryClientProvider>
      </KindeProvider>
    </LocalizationProvider>
  );
}

export default App;
