import {
  Box,
  Button,
  Card,
  FormControl,
  FormHelperText,
  FormLabel,
  Input,
  Stack,
  Typography,
  useTheme,
} from "@mui/joy";
import {
  useAddUserToOrgMutation,
  useCreateUserQuery,
  useSearchUserByEmailQuery,
} from "../../services/UserService";
import { useEffect, useState } from "react";
import { InfoOutlined, ErrorOutlineOutlined } from "@mui/icons-material";
import { MutationError } from "../MutateError";
import { MutationSuccess } from "../MutateSuccess";
import { useQueryClient } from "@tanstack/react-query";

interface UserCreateProps {
  closeAction: () => void;
}

export function UserCreate(props: UserCreateProps) {
  const { closeAction } = props;

  const [email, setEmail] = useState<string>("");
  const [emailValid, setEmailValid] = useState<boolean>(false);

  const [firstName, setFirstName] = useState<string>("");
  const [firstNameValid, setFirstNameValid] = useState<boolean>(false);

  const [lastName, setLastName] = useState<string>("");
  const [lastNameValid, setLastNameValid] = useState<boolean>(false);

  const [userAdded, setUserAdded] = useState<boolean>(false);
  const [userCreated, setUserCreated] = useState<boolean>(false);

  const theme = useTheme();

  const [searchEnabled, setSearchEnabled] = useState(false); // State to control when the query should run

  const queryClient = useQueryClient();

  const {
    data: user,
    isLoading,
    error,
    isFetched,
  } = useSearchUserByEmailQuery(email, searchEnabled);

  const addUserMutation = useCreateUserQuery({
    onSuccessCallback: () => {
      setUserCreated(true);
    },
  });
  const addUserToOrgMutation = useAddUserToOrgMutation({
    onSuccessCallback: () => {
      setUserAdded(true);
    },
  });

  useEffect(() => {
    queryClient.removeQueries({ queryKey: ["userSearch", email] });
    setSearchEnabled(false);

    setFirstName("");
    setLastName("");
  }, [email, queryClient]);

  const handleAddUser = async () => {
    if (user) {
      addUserToOrgMutation.mutate({ userId: user.id });
    } else {
      addUserMutation.mutate({
        email: email,
        first_name: firstName,
        last_name: lastName,
      });
    }
  };

  const validateEmail = (email: string): void => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (emailRegex.test(email)) {
      setEmailValid(true);
    } else {
      setEmailValid(false);
    }
  };

  const validateName = (name: string): boolean => {
    const nameRegex = /^[\p{L} ,.'-]+$/u;
    return nameRegex.test(name);
  };

  useEffect(() => {
    setFirstNameValid(validateName(firstName));
  }, [firstName]);

  useEffect(() => {
    setLastNameValid(validateName(lastName));
  }, [lastName]);

  return (
    <Box sx={{ flexGrow: "1", display: "flex", flexDirection: "column" }}>
      <Card sx={{ flexGrow: 1 }}>
        <Stack spacing={3}>
          <FormControl sx={{ marginLeft: "10px", marginRight: "10px" }}>
            <FormLabel
              sx={{ color: theme.vars.palette.text.tertiary }}
              component="legend"
            >
              Email
            </FormLabel>
            <Input
              type="email"
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setEmail(event.target.value);
                validateEmail(event.target.value);
              }}
              disabled={userAdded || userCreated}
            />
            {!emailValid && (
              <FormHelperText
                sx={{ color: theme.palette.danger[700], fontStyle: "italic" }}
              >
                <ErrorOutlineOutlined />
                Ugyldig email
              </FormHelperText>
            )}
          </FormControl>
          {isFetched && (
            <>
              <FormControl sx={{ marginLeft: "10px", marginRight: "10px" }}>
                <FormLabel
                  sx={{ color: theme.vars.palette.text.tertiary }}
                  component="legend"
                >
                  {user ? "Fornavn" : "Indtast fornavn"}
                </FormLabel>
                <Input
                  value={user?.first_name ? user.first_name : firstName}
                  disabled={user || userAdded || userCreated ? true : false}
                  type="text"
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setFirstName(event.target.value);
                  }}
                />
                {!user && !firstNameValid && (
                  <FormHelperText
                    sx={{
                      color: theme.palette.danger[700],
                      fontStyle: "italic",
                    }}
                  >
                    <ErrorOutlineOutlined />
                    Indtast navn
                  </FormHelperText>
                )}
              </FormControl>
              <FormControl sx={{ marginLeft: "10px", marginRight: "10px" }}>
                <FormLabel
                  sx={{ color: theme.vars.palette.text.tertiary }}
                  component="legend"
                >
                  {user ? "Efternavn" : "Indtast efternavn"}
                </FormLabel>
                <Input
                  value={user ? user.last_name : lastName}
                  disabled={user || userAdded || userCreated ? true : false}
                  type="text"
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setLastName(event.target.value);
                  }}
                />
                {!user && !lastNameValid && (
                  <FormHelperText
                    sx={{
                      color: theme.palette.danger[700],
                      fontStyle: "italic",
                    }}
                  >
                    <ErrorOutlineOutlined />
                    Indtast efternavn
                  </FormHelperText>
                )}
              </FormControl>
            </>
          )}
        </Stack>

        {error && <MutationError message={error.message} />}
        {isFetched && user && !userAdded && (
          <MutationSuccess message="Bruger fundet" />
        )}
        {userAdded && user && (
          <MutationSuccess message={`${user.full_name} tilføjet`} />
        )}
        {userCreated && (
          <MutationSuccess message={`${firstName} ${lastName} tilføjet`} />
        )}
        {isFetched && !user && !userCreated && (
          <Typography
            sx={{
              margin: "20px 5px 20px",
              display: "flex",
              justifyContent: "center",
              color: theme.palette.success[600],
              fontWeight: theme.fontWeight.md,
            }}
          >
            <InfoOutlined
              sx={{ marginRight: 0.5, color: theme.palette.success[600] }}
            />
            Brugeren findes ikke i mediskill, opret en ny
          </Typography>
        )}

        {isFetched && !userAdded && !userCreated && (
          <Button
            role="submit"
            onClick={handleAddUser}
            sx={{
              margin: "20px 0px",
              width: "100%",
              backgroundColor: theme.palette.primary[700],
              "&:hover": {
                backgroundColor: theme.palette.primary[800],
              },
            }}
            loading={
              addUserToOrgMutation.isPending || addUserMutation.isPending
            }
            disabled={
              isLoading ||
              (!user && (!emailValid || !firstNameValid || !lastNameValid))
            }
          >
            {user ? "Tilføj bruger" : "Opret bruger"}
          </Button>
        )}

        {!user && !isFetched && (
          <Button
            role="submit"
            onClick={() => {
              setSearchEnabled(true);
            }}
            sx={{
              margin: "20px 0px",
              width: "100%",
              backgroundColor: theme.palette.primary[700],
              "&:hover": {
                backgroundColor: theme.palette.primary[800],
              },
            }}
            loading={isLoading}
            disabled={isLoading || !emailValid}
          >
            Søg efter bruger
          </Button>
        )}

        <Button
          role="submit"
          onClick={closeAction}
          sx={{
            margin: "20px 0px",
            width: "100%",
            backgroundColor: theme.palette.primary[700],
            "&:hover": {
              backgroundColor: theme.palette.primary[800],
            },
          }}
          disabled={
            isLoading ||
            addUserToOrgMutation.isPending ||
            addUserMutation.isPending
          }
        >
          Luk
        </Button>
      </Card>
    </Box>
  );
}
