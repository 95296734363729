import { Box, Typography } from "@mui/joy";

interface ErrorProps {
  message: string;
}

export function Error(props: ErrorProps) {
  return (
    <Box display="flex" alignItems="center">
      <Typography level="title-md" padding={0.5} color="danger">
        Error: {props.message}
      </Typography>
    </Box>
  );
}
