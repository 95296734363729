import {
  Card,
  FormControl,
  FormLabel,
  Input,
  Stack,
  Switch,
  switchClasses,
  Typography,
  useTheme,
  Button,
} from "@mui/joy";
import { Box, CircularProgress } from "@mui/material";
import { useEffect, useState } from "react";
import PersonIcon from "@mui/icons-material/Person";
import CheckIcon from "@mui/icons-material/Check";
import React from "react";
import { useKindeAuth } from "@kinde-oss/kinde-auth-react";
import {
  KindeRoleKey,
  KindeRoles,
  OrganizationDetails,
  Role,
  useAddUserRoleMutation,
  User,
  useRemoveUserRoleMutation,
} from "../../services/UserService";
import { UserDelete } from "./UserDelete";

interface UserMutateProps {
  /**
   * Function to be called when the mutate action is closed.
   */
  closeAction: () => void;

  user: User;

  organizationDetails: OrganizationDetails;
}

export function UserMutate(props: UserMutateProps) {
  const theme = useTheme();
  const { closeAction, user, organizationDetails } = props;
  const { getPermission } = useKindeAuth();
  const addRoleMutation = useAddUserRoleMutation();
  const removeRoleMutation = useRemoveUserRoleMutation();

  const [roles, setRoles] = useState<{ [key: string]: boolean }>({
    [KindeRoleKey.UserManager]: false,
    [KindeRoleKey.Student]: false,
    [KindeRoleKey.Supervisor]: false,
  });

  const [rolesWaiting, setRolesWaiting] = useState<{ [key: string]: boolean }>({
    [KindeRoleKey.UserManager]: false,
    [KindeRoleKey.Student]: false,
    [KindeRoleKey.Supervisor]: false,
  });

  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    setLoading(addRoleMutation.isPending || removeRoleMutation.isPending);

    if (!addRoleMutation.isPending && !removeRoleMutation.isPending) {
      setRolesWaiting((prevRolesWaiting) => {
        const newRolesWaiting = Object.keys(prevRolesWaiting).reduce(
          (acc, key) => {
            acc[key] = false;
            return acc;
          },
          {} as { [key: string]: boolean }
        );

        return newRolesWaiting;
      });
    }
  }, [addRoleMutation.isPending, removeRoleMutation.isPending]);

  const handleToggle = async (role: keyof typeof roles) => {
    setRoles((prevRoles) => ({
      ...prevRoles,
      [role]: !prevRoles[role],
    }));

    setRolesWaiting((prevRoles) => ({ ...prevRoles, [role]: true }));

    if (!roles[role]) {
      addRoleMutation.mutate({ userId: user.id, role: role as string });
    } else {
      removeRoleMutation.mutate({ userId: user.id, role: role as string });
    }
  };

  useEffect(() => {
    const userRoles: Role[] = user.roles;

    userRoles.forEach((role) => {
      setRoles((prevRoles) => ({
        ...prevRoles,
        [role.key]: true,
      }));
    });
  }, [user.roles]);

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
      <Box
        sx={{ display: "flex", flexDirection: "row", gap: 2, height: "100%" }}
      >
        <Box
          sx={{ flexGrow: "auto", display: "flex", flexDirection: "column" }}
        >
          <Card sx={{ flexGrow: 1, width: "160px" }}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
              }}
            >
              {user.picture != null ? (
                <img src={user.picture} alt="" />
              ) : (
                <Box sx={{ fontSize: "160px" }}>
                  <PersonIcon fontSize="inherit" />
                </Box>
              )}
            </Box>
          </Card>
        </Box>
        <Box sx={{ flexGrow: "1", display: "flex", flexDirection: "column" }}>
          <Card sx={{ flexGrow: 1 }}>
            <Stack spacing={3}>
              <FormControl sx={{ marginLeft: "10px", marginRight: "10px" }}>
                <FormLabel
                  sx={{ color: theme.vars.palette.text.tertiary }}
                  component="legend"
                >
                  Fornavn
                </FormLabel>
                <Input
                  value={user.first_name}
                  readOnly={true}
                  disabled={true}
                />
              </FormControl>
              <FormControl sx={{ marginLeft: "10px", marginRight: "10px" }}>
                <FormLabel
                  sx={{ color: theme.vars.palette.text.tertiary }}
                  component="legend"
                >
                  Efternavn
                </FormLabel>
                <Input value={user.last_name} readOnly={true} disabled={true} />
              </FormControl>
              <FormControl sx={{ marginLeft: "10px", marginRight: "10px" }}>
                <FormLabel
                  sx={{ color: theme.vars.palette.text.tertiary }}
                  component="legend"
                >
                  Email
                </FormLabel>
                <Input value={user.email} readOnly={true} disabled={true} />
              </FormControl>
            </Stack>
          </Card>
        </Box>
      </Box>
      {(getPermission("role:grant-student").isGranted ||
        getPermission("role:grant-supervisor").isGranted ||
        getPermission("role:grant-useradmin").isGranted) && (
        <Card>
          <Typography
            textColor={theme.palette.primary[700]}
            level="h4"
            fontWeight={theme.fontWeight.md}
          >
            Roller
          </Typography>
          {Object.keys(roles).map((roleKey) => (
            <Box
              key={roleKey}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Typography variant="plain">
                {KindeRoles.getRoleByKey(roleKey)?.name}
              </Typography>
              <Switch
                key={roleKey}
                checked={roles[roleKey]}
                disabled={loading}
                onChange={() => handleToggle(roleKey as keyof typeof roles)}
                size="lg"
                sx={{
                  "--Switch-trackWidth": "50px",
                  [`&.${switchClasses.checked}`]: {
                    "--Switch-trackBackground": theme.palette.primary[700],
                    "--Switch-thumbBackground": theme.palette.primary[50],
                    "&:hover": {
                      "--Switch-trackBackground": theme.palette.primary[900],
                    },
                  },
                }}
                slotProps={{
                  track: {
                    children: (
                      <React.Fragment>
                        {rolesWaiting[roleKey] ? (
                          <>
                            <CircularProgress
                              size="16px"
                              sx={{
                                color: theme.palette.primary[50],
                                marginLeft: 0.5,
                              }}
                            />
                            <CircularProgress
                              size="16px"
                              sx={{
                                color: theme.palette.primary[700],
                                marginLeft: 0.5,
                              }}
                            />
                          </>
                        ) : (
                          <CheckIcon />
                        )}
                      </React.Fragment>
                    ),
                  },
                }}
              />
            </Box>
          ))}
        </Card>
      )}
      <UserDelete
        user={user}
        organizationDetails={organizationDetails}
        isLoading={loading}
        setLoading={setLoading}
      />

      <Button
        role="submit"
        onClick={closeAction}
        sx={{
          margin: "20px 0px",
          width: "100%",
          backgroundColor: theme.palette.primary[700],
          "&:hover": {
            backgroundColor: theme.palette.primary[800],
          },
        }}
        disabled={loading}
      >
        Luk
      </Button>
    </Box>
  );
}
