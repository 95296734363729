import Button from "@mui/joy/Button";
import { Box, Typography, useTheme } from "@mui/joy";
import { DangerousOutlined } from "@mui/icons-material";
import { MutationError } from "../MutateError";
import { MutationSuccess } from "../MutateSuccess";
import { Education } from "../../services/EducationService";
import { UseMutationResult } from "@tanstack/react-query";

interface DeleteEducationVariables {
  type: string;
}

/**
 * Props for the EducationDelete component.
 */
interface EducationDeleteProps {
  education: Education;
  loading: boolean;
  isDeleted: boolean;
  setIsDeleted: React.Dispatch<React.SetStateAction<boolean>>;
  checkDelete: boolean;
  setCheckDelete: React.Dispatch<React.SetStateAction<boolean>>;
  mutationDelete: UseMutationResult<
    unknown,
    Error,
    DeleteEducationVariables,
    unknown
  >;
}

export function EducationDelete(props: EducationDeleteProps) {
  const {
    education,
    isDeleted,
    setIsDeleted,
    checkDelete,
    setCheckDelete,
    mutationDelete,
    loading,
  } = props;

  const handleDelete = async function () {
    mutationDelete.mutate(
      { type: education.type },
      {
        onSuccess: (data, variables, context) => {
          setIsDeleted(true);
        },
      }
    );
  };

  const theme = useTheme();
  return (
    <>
      {!checkDelete &&
        !isDeleted && ( //Show option to delete
          <Box sx={{ display: "flex" }}>
            <Button
              variant="solid"
              color="danger"
              sx={{
                textTransform: "none",
                width: "100%",
                backgroundColor: theme.palette.danger[700],
                "&:hover": {
                  backgroundColor: theme.palette.danger[800],
                },
              }}
              onClick={() => {
                setCheckDelete(true);
              }}
              disabled={mutationDelete.isPending || loading}
              loading={mutationDelete.isPending}
            >
              Fjern uddannelsesforløb
            </Button>
          </Box>
        )}

      {
        //Show any errors in deleting
        mutationDelete.error && (
          <MutationError message={mutationDelete.error.message} />
        )
      }

      {checkDelete && !isDeleted && (
        <>
          <Box
            sx={{
              margin: "20px 0px 0px",
              color: theme.palette.danger[700],
              display: "flex",
              alignItems: "center",
            }}
          >
            <DangerousOutlined sx={{ fontSize: "2em", marginLeft: "10px" }} />
            <Typography
              level="body-md"
              textColor="danger.700"
              sx={{ marginLeft: "30px" }}
            >
              Er du sikker på du vil fjerne uddannelsesforløbet?
            </Typography>
          </Box>

          <Box sx={{ display: "flex", flexDirection: "rows", gap: 3 }}>
            <Button
              role="submit"
              disabled={mutationDelete.isPending}
              onClick={() => {
                setCheckDelete(false);
              }}
              sx={{
                margin: "20px 0px",
                width: "100%",
                backgroundColor: theme.palette.primary[700],
                "&:hover": {
                  backgroundColor: theme.palette.primary[800],
                },
              }}
            >
              Nej
            </Button>
            <Button
              role="submit"
              disabled={mutationDelete.isPending}
              loading={mutationDelete.isPending}
              onClick={handleDelete}
              sx={{
                margin: "20px 0px",
                width: "100%",
                backgroundColor: theme.palette.danger[700],
                "&:hover": {
                  backgroundColor: theme.palette.danger[800],
                },
              }}
            >
              Ja
            </Button>
          </Box>
        </>
      )}

      {isDeleted && <MutationSuccess message={"Uddannelsesforløb fjernet"} />}
    </>
  );
}
