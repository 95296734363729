import { Box, Typography, Avatar } from "@mui/material";
import { useTheme } from "@mui/joy";
import { Loading } from "../Loading";
import FormatListBulletedOutlinedIcon from "@mui/icons-material/FormatListBulletedOutlined";

interface ProcedureStatsCardProps {
  isLoading: boolean;
  proceduresCurrentMonth: number | undefined;
  proceduresPreviousMonth: number | undefined;
}

export function ProcedureStatCard(props: ProcedureStatsCardProps) {
  const theme = useTheme();
  const { proceduresCurrentMonth, proceduresPreviousMonth, isLoading } = props;

  let percentageChange = 0;

  if (
    proceduresPreviousMonth &&
    proceduresCurrentMonth &&
    proceduresPreviousMonth !== 0
  ) {
    percentageChange = Math.round((proceduresCurrentMonth / proceduresPreviousMonth - 1) * 100);
  }

  return (
    <Box
      sx={{
        position: "relative",
        backgroundColor: "white",
        borderRadius: 2,
        padding: 2,
        boxShadow: 1,
        width: 175,
        maxHeight: 125,
        margin: 2,
      }}
    >
      <Avatar
        sx={{
          position: "absolute",
          top: -10,
          left: 10,
          backgroundColor: theme.palette.primary[700],
          width: 56,
          height: 56,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          borderRadius: 2, // Makes the avatar square with slightly rounded corners
        }}
      >
        <FormatListBulletedOutlinedIcon sx={{ fontSize: 32 }} />
      </Avatar>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 0,
        }}
      >
        <Box sx={{ marginLeft: 8, marginTop: -2, height: 42 }}>
          <Typography variant="subtitle2" color="textPrimary">
            Procedurer logget
          </Typography>
        </Box>
        {isLoading && <Loading />}
        {!isLoading && (
          <>
            <Box sx={{ marginLeft: 8 }}>
              <Typography variant="h4" color="textPrimary">
                {proceduresCurrentMonth}
              </Typography>
            </Box>
            <Box sx={{ marginLeft: 1 }}>
              <Typography
                variant="body2"
                sx={{ color: percentageChange > 0 ? "green" : "red" }}
              >
                {percentageChange > 0 ? "+" : ""}
                {percentageChange}% i forhold til sidste måned (
                {proceduresPreviousMonth})
              </Typography>
            </Box>
          </>
        )}
      </Box>
    </Box>
  );
}
