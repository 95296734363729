import React from "react";
import { Box, Typography, Avatar } from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import { useTheme } from "@mui/joy";

interface UserStatsCardProps {
  activeUsers: number;
  percentageChange: number;
}

const UserStatsCard: React.FC<UserStatsCardProps> = ({
  activeUsers,
  percentageChange,
}) => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        position: "relative",
        backgroundColor: "white",
        borderRadius: 2,
        padding: 2,
        boxShadow: 1,
        width: 175,
        maxHeight: 125,
        margin: 2,
      }}
    >
      <Avatar
        sx={{
          position: "absolute",
          top: -10,
          left: 10,
          backgroundColor: theme.palette.primary[700],
          width: 56,
          height: 56,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          borderRadius: 2, // Makes the avatar square with slightly rounded corners
        }}
      >
        <PersonIcon sx={{ fontSize: 32 }} />
      </Avatar>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 1,
        }}
      >
        <Box
          sx={{
            marginLeft: 8,
            marginTop: -2,
            height: 42,
            alignContent: "center",
          }}
        >
          <Typography variant="subtitle2" color="textPrimary">
            Users Active
          </Typography>
        </Box>
        <Box sx={{ marginLeft: 10 }}>
          <Typography variant="h4" color="textPrimary">
            {activeUsers.toLocaleString()}
          </Typography>
        </Box>
        <Box sx={{ marginLeft: 1 }}>
          <Typography
            variant="body2"
            sx={{ color: percentageChange > 0 ? "green" : "red" }}
          >
            {percentageChange > 0 ? "+" : ""}
            {percentageChange}% than last week
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default UserStatsCard;
