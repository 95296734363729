import { Modal, ModalClose, ModalDialog, Typography, useTheme } from "@mui/joy";
import React, { useCallback } from "react";

/**
 * Props for the ModalWrapper component.
 */
interface ModalWrapperProps {
  /**
   * The title of the modal dialog.
   */
  modalTitle: string;

  /**
   * Controls whether the modal is open or closed.
   */
  open: boolean;

  /**
   * A function to toggle the open state of the modal.
   */
  setOpen: (value: boolean) => void;

  /**
   * A function that returns the content of the modal dialog.
   *
   * @param close - A function that can be used to close the modal.
   *
   * @returns The content to be rendered inside the modal.
   */
  children: (close: () => void) => React.ReactNode;

  /**
   * An optional async function to be executed when the modal is closed.
   * This can be useful for performing cleanup actions or asynchronous tasks.
   */
  closeAction?: () => Promise<void>;
}

/**
 * A reusable modal component that provides a customizable modal dialog.
 * It accepts a title, content, and optional close action.
 *
 * @param props - Props for the ModalWrapper component.
 * @returns A modal dialog with the specified title and content.
 */
export function ModalWrapper(props: ModalWrapperProps) {
  const { modalTitle, open, setOpen, children, closeAction } = props;

  /**
   * Callback function to close the modal.
   * It toggles the open state of the modal and executes the close action if provided.
   */
  const close = useCallback(async () => {
    setOpen(false);
    if (closeAction) return closeAction();
  }, [closeAction, setOpen]);

  const theme = useTheme();
  return (
    <Modal open={open} onClose={() => close()}>
      <ModalDialog sx={{ width: "600px", overflowY: "auto" }}>
        <ModalClose />
        <Typography
          textColor={theme.palette.primary[700]}
          component="h2"
          id="modal-title"
          level="h4"
          fontWeight={theme.fontWeight.md}
        >
          {modalTitle}
        </Typography>
        {children(close)}
      </ModalDialog>
    </Modal>
  );
}
