import {
  Card,
  FormControl,
  FormLabel,
  Stack,
  useTheme,
  Button,
  Select,
  Option,
  FormHelperText,
} from "@mui/joy";
import { Box } from "@mui/material";
import { useEffect, useState } from "react";
import {
  Education,
  EducationTypes,
  translateEducationKeyToValue,
  useDeleteEducationMutation,
  usePatchEducationMutation,
} from "../../services/EducationService";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { ErrorOutlineOutlined } from "@mui/icons-material";
import { MutationError } from "../MutateError";
import { MutationSuccess } from "../MutateSuccess";
import { EducationDelete } from "./EducationDelete";

interface EducationMutateProps {
  /**
   * Function to be called when the mutate action is closed.
   */
  closeAction: () => void;

  education: Education;

  options: EducationTypes;
}

export function EducationMutate(props: EducationMutateProps) {
  const theme = useTheme();
  const { closeAction, education, options } = props;
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [dateValid, setDateValid] = useState<boolean>(false);
  const [isDeleted, setIsDeleted] = useState<boolean>(false);

  const [checkDelete, setCheckDelete] = useState<boolean>(false);

  useEffect(() => {
    setStartDate(education.start_date);
    setEndDate(education.end_date);
  }, [education]);

  useEffect(() => {
    const isDateValid =
      startDate !== null && (endDate === null || startDate < endDate);

    setDateValid(isDateValid);
  }, [startDate, endDate]);

  const mutationPatch = usePatchEducationMutation();
  const mutationDelete = useDeleteEducationMutation();

  const handleSubmit = async function () {
    if (startDate) {
      mutationPatch.mutate({
        type: education.type,
        start_date: startDate,
        end_date: endDate,
      });
    }
  };

  return (
    <Box sx={{ flexGrow: "1", display: "flex", flexDirection: "column" }}>
      <Card sx={{ flexGrow: 1 }}>
        <Stack spacing={3}>
          <FormControl sx={{ marginLeft: "10px", marginRight: "10px" }}>
            <FormLabel
              sx={{ color: theme.vars.palette.text.tertiary }}
              component="legend"
            >
              Type
            </FormLabel>
            <Select
              value={education.type}
              placeholder="Vælg uddannelsestype"
              disabled={true}
            >
              <Option key={education.type} value={education.type}>
                {translateEducationKeyToValue(options, education.type)}
              </Option>
            </Select>
          </FormControl>
          <Box sx={{ display: "flex", gap: 2 }}>
            <FormControl sx={{ width: "50%" }}>
              <Box>
                <DatePicker
                  label="Start dato*"
                  slotProps={{ textField: { size: "small" } }}
                  onChange={(newValue) => setStartDate(newValue)}
                  disabled={
                    mutationPatch.isPending ||
                    mutationDelete.isPending ||
                    mutationDelete.isSuccess
                  }
                  value={education.start_date}
                />
              </Box>
            </FormControl>
            <FormControl sx={{ width: "50%" }}>
              <Box>
                <DatePicker
                  label="Slut dato"
                  slotProps={{ textField: { size: "small" } }}
                  onChange={(newValue) => setEndDate(newValue)}
                  disabled={
                    mutationPatch.isPending ||
                    mutationDelete.isPending ||
                    mutationDelete.isSuccess
                  }
                  value={education.end_date}
                />
              </Box>
            </FormControl>
          </Box>
          <Box>
            {!dateValid && startDate && endDate && (
              <FormHelperText
                sx={{
                  color: theme.palette.danger[700],
                  fontStyle: "italic",
                }}
              >
                <ErrorOutlineOutlined />
                Slut dato skal være efter end start dato
              </FormHelperText>
            )}
          </Box>
        </Stack>

        {mutationPatch.error && (
          <MutationError message={mutationPatch.error.message} />
        )}
        {mutationPatch.isSuccess && (
          <MutationSuccess message="Uddannelsesforløb updateret" />
        )}

        {!isDeleted && !checkDelete && !mutationPatch.isSuccess && (
          <Button
            role="submit"
            sx={{
              margin: "20px 0px",
              width: "100%",
              backgroundColor: theme.palette.primary[700],
              "&:hover": {
                backgroundColor: theme.palette.primary[800],
              },
            }}
            loading={
              mutationPatch.isPending ||
              mutationDelete.isPending ||
              mutationDelete.isSuccess
            }
            disabled={
              !dateValid ||
              mutationPatch.isPending ||
              mutationDelete.isPending ||
              mutationDelete.isSuccess
            }
            onClick={handleSubmit}
          >
            Opdater uddannelsesforløb
          </Button>
        )}

        {!mutationPatch.isSuccess && (
          <EducationDelete
            education={education}
            setIsDeleted={setIsDeleted}
            isDeleted={isDeleted}
            loading={mutationPatch.isPending}
            checkDelete={checkDelete}
            setCheckDelete={setCheckDelete}
            mutationDelete={mutationDelete}
          />
        )}

        {(isDeleted || mutationPatch.isSuccess) && (
          <Button
            role="submit"
            onClick={closeAction}
            sx={{
              margin: "20px 0px",
              width: "100%",
              backgroundColor: theme.palette.primary[700],
              "&:hover": {
                backgroundColor: theme.palette.primary[800],
              },
            }}
          >
            Luk
          </Button>
        )}
      </Card>
    </Box>
  );
}
