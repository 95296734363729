import {
  Select,
  Option,
  RadioGroup,
  Radio,
  Stack,
  Box,
  Checkbox,
  Typography,
  Textarea,
  Button,
  useTheme,
  Input,
  Slider,
} from "@mui/joy";
import LabeledSection from "./LabeledSection";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { FormControlLabel } from "@mui/material";

export interface InputOptions {
  id: string;
  label: string;
}
export function transformResponseToInputOptions(data: {
  [key: string]: string;
}): InputOptions[] {
  return Object.keys(data).map((key) => ({
    id: key,
    label: data[key],
  }));
}

interface MediSkillSelectProps {
  label: string;

  options: InputOptions[];

  handleChange: (
    event: React.SyntheticEvent | null,
    newValue: string | null
  ) => void;

  direction: "row" | "column";

  required?: boolean;

  disabled?: boolean;
}

export function MediSkillSelect(props: MediSkillSelectProps) {
  const {
    label,
    options,
    handleChange,
    direction,
    required = false,
    disabled = false,
  } = props;
  return (
    <LabeledSection
      label={label}
      showRequired={required}
      component={
        <Select onChange={handleChange} disabled={disabled}>
          {options.map((option) => (
            <Option
              key={option.id}
              value={option.id}
              slotProps={{
                root: {
                  "data-key": option.id,
                },
              }}
            >
              {option.label}
            </Option>
          ))}
        </Select>
      }
      direction={direction}
    />
  );
}

interface MediSkillDatePickerProps {
  label: string;

  onChange: (input: any) => void;

  direction: "row" | "column";

  maxDate?: Date;

  required?: boolean;

  procedureDate: Date | null;

  disabled?: boolean;
}

export function MediSkillDatePicker(props: MediSkillDatePickerProps) {
  const {
    label,
    onChange,
    direction,
    maxDate = new Date(),
    required = false,
    procedureDate,
    disabled = false,
  } = props;
  return (
    <LabeledSection
      label={label}
      showRequired={required}
      component={
        <DatePicker
          slotProps={{ textField: { size: "small" } }}
          onChange={onChange}
          maxDate={maxDate}
          value={procedureDate}
          disabled={disabled}
        />
      }
      direction={direction}
    />
  );
}

interface MediSKillRadioGroupProps {
  label: string;

  groupName: string;

  options: InputOptions[];

  selectedValue: string;

  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;

  direction: "row" | "column";

  required?: boolean;

  showDivider?: boolean;

  disabled?: boolean;
}

export function MediSKillRadioGroup(props: MediSKillRadioGroupProps) {
  const {
    label,
    groupName,
    options,
    selectedValue,
    handleChange,
    direction,
    required = false,
    showDivider = true,
    disabled = false,
  } = props;

  return (
    <LabeledSection
      label={label}
      showRequired={required}
      showDivider={showDivider}
      component={
        <RadioGroup
          name={groupName}
          value={selectedValue}
          onChange={handleChange}
        >
          {options.map((option) => (
            <FormControlLabel
              key={option.id}
              value={option.id}
              label={option.label}
              control={
                <Radio
                  slotProps={{
                    input: {
                      "data-key": option.id, // Pass the key as a data attribute
                    },
                  }}
                  sx={{ paddingRight: 1 }}
                  disabled={disabled}
                />
              }
              sx={{ paddingBottom: 1 }}
            />
          ))}
        </RadioGroup>
      }
      direction={direction}
    />
  );
}

interface MediSkillCheckboxProps {
  label: string;

  options: InputOptions[];

  selectedValues: string[];

  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;

  direction: "row" | "column";

  required?: boolean;

  disabled?: boolean;
}

export function MediSkillCheckbox(props: MediSkillCheckboxProps) {
  const {
    label,
    options,
    selectedValues,
    handleChange,
    direction,
    required = false,
    disabled = false,
  } = props;
  return (
    <LabeledSection
      label={label}
      showRequired={required}
      component={
        <Stack spacing={2}>
          {options.map((option) => (
            <label key={option.id}>
              <Box sx={{ display: "flex" }}>
                <Checkbox
                  checked={selectedValues.includes(option.id)}
                  onChange={handleChange}
                  value={option.label}
                  sx={{ marginRight: 1 }}
                  slotProps={{
                    input: {
                      "data-key": option.id, // Pass the key as a data attribute
                    },
                  }}
                  disabled={disabled}
                />
                <Typography level="inherit">{option.label}</Typography>
              </Box>
            </label>
          ))}
        </Stack>
      }
      direction={direction}
    />
  );
}

interface MediSkillTextareaProps {
  label: string;

  handleChange: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;

  direction?: "row" | "column";

  required?: boolean;

  disabled?: boolean;
}

export function MediSkillTextarea(props: MediSkillTextareaProps) {
  const {
    label,
    handleChange,
    direction = "column",
    required = false,
    disabled = false,
  } = props;
  return (
    <LabeledSection
      label={label}
      showRequired={required}
      component={
        <Textarea minRows={4} onChange={handleChange} disabled={disabled} />
      }
      direction={direction}
    />
  );
}

interface MediSkillTextInputProps {
  label: string;

  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;

  selectedValue: string;

  direction?: "row" | "column";

  required?: boolean;

  disabled?: boolean;
}

export function MediSkillTextInput(props: MediSkillTextInputProps) {
  const {
    label,
    handleChange,
    selectedValue,
    direction = "column",
    required = false,
    disabled = false,
  } = props;
  return (
    <LabeledSection
      component={
        <Input
          value={selectedValue}
          onChange={handleChange}
          placeholder="alder"
          type="text"
          disabled={disabled}
        />
      }
      label={label}
      showRequired={required}
      direction={direction}
    />
  );
}

interface MediSkillSliderProps {
  label: string;

  labelMin?: string;

  labelMid?: string;

  labelMax?: string;

  selectedValue: number;

  min: number;

  max: number;

  direction?: "row" | "column";

  handleChange: (event: Event, newValue: number | number[]) => void;

  disabled?: boolean;
}
export function MediSkillSlider(props: MediSkillSliderProps) {
  const {
    label,
    labelMin = "",
    labelMid = "",
    labelMax = "",
    selectedValue,
    min,
    max,
    direction = "column",
    handleChange,
    disabled = false,
  } = props;
  return (
    <LabeledSection
      label={label}
      direction={direction}
      component={
        <>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Box>{labelMin}</Box>
            <Box>{labelMid}</Box>
            <Box>{labelMax}</Box>
          </Box>
          <Slider
            value={selectedValue}
            onChange={handleChange}
            min={min}
            max={max}
            step={1}
            disabled={disabled}
          />
        </>
      }
    />
  );
}

interface MediSkillSubmitButtonProps {
  label: string;

  handleSubmit: () => void;

  loading?: boolean;

  disabled?: boolean;
}

export function MediSkillSubmitButton(props: MediSkillSubmitButtonProps) {
  const { label, handleSubmit, loading = false, disabled = false } = props;
  const theme = useTheme();
  return (
    <Button
      role="submit"
      sx={{
        margin: "20px 0px",
        width: "100%",
        backgroundColor: theme.palette.primary[700],
        "&:hover": {
          backgroundColor: theme.palette.primary[800],
        },
      }}
      onClick={handleSubmit}
      loading={loading}
      disabled={disabled}
    >
      {label}
    </Button>
  );
}

interface MediSkillCloseButtonProps {
  label?: string;

  loading?: boolean;

  disabled?: boolean;

  closeAction?: () => void;
}
export function MediSkillCloseButton(props: MediSkillCloseButtonProps) {
  const {
    label = "Luk",
    loading = false,
    disabled = false,
    closeAction,
  } = props;
  const theme = useTheme();
  return (
    <Button
      role="submit"
      onClick={closeAction}
      sx={{
        margin: "20px 0px",
        width: "100%",
        backgroundColor: theme.palette.primary[700],
        "&:hover": {
          backgroundColor: theme.palette.primary[800],
        },
      }}
      loading={loading}
      disabled={disabled}
    >
      {label}
    </Button>
  );
}
