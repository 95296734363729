import { useQuery } from "@tanstack/react-query";
import { ApiService } from "./ApiService";
import { useAuth } from "./AuthProvider";
import { UserWithProcedureCount } from "./UserService";

interface ProcedureCount {
  current_month_count: number;
  previous_month_count: number;
}

export const useFetchProcedureCountQuery = () => {
  const { token } = useAuth();
  const apiService = new ApiService(token);

  return useQuery({
    queryKey: ["procedureCount"],
    queryFn: async () => {
      return await apiService.makeRequest<ProcedureCount>(
        "/api/v1/stats/procedures"
      );
    },
    enabled: token !== "",
  });
};

export const useFetchUserCountsQuery = () => {
  const { token } = useAuth();
  const apiService = new ApiService(token);

  return useQuery({
    queryKey: ["userCount"],
    queryFn: async () => {
      return await apiService.makeRequest<UserWithProcedureCount[]>(
        "/api/v1/stats/users"
      );
    },
    enabled: token !== "",
  });
};