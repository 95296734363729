import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useAuth } from "./AuthProvider";
import { ApiService } from "./ApiService";
import { format } from "date-fns";

export interface EducationTypes {
  [key: string]: string;
}

export function translateEducationKeyToValue(
  educationTypes: EducationTypes | null,
  key: string
): string {
  return educationTypes?.[key] ?? "undefined";
}

export class Education {
  id: string;
  created_at: Date | null;
  start_date: Date;
  end_date: Date | null;
  type: string;
  updated_at: Date | null;
  user_id: string;

  constructor(
    id: string,
    created_at: string | null,
    start_date: string,
    end_date: string | null,
    type: string,
    updated_at: string | null,
    user_id: string
  ) {
    this.id = id;
    this.created_at = created_at ? new Date(created_at) : null;
    this.start_date = new Date(start_date);
    this.end_date = end_date ? new Date(end_date) : null;
    this.type = type;
    this.updated_at = updated_at ? new Date(updated_at) : null;
    this.user_id = user_id;
  }
}

export const useFetchEducationsQuery = () => {
  const { token } = useAuth();
  const apiService = new ApiService(token);

  return useQuery({
    queryKey: ["educations"],
    queryFn: async () => {
      const jsonData = await apiService.makeRequest<any[]>("/api/v1/education");

      return jsonData.map(
        (data: any) =>
          new Education(
            data.id,
            data.created_at,
            data.start_date,
            data.end_date,
            data.type,
            data.updated_at,
            data.user_id
          )
      ) as Education[];
    },
    enabled: token !== "",
  });
};

export const useEducationTypesQuery = () => {
  const { token } = useAuth();
  const apiService = new ApiService(token);
  return useQuery({
    queryKey: ["educationTypes"],
    queryFn: async () => {
      return apiService.makeRequest<EducationTypes>(
        "/api/v1/data/educationtypes"
      );
    },
    enabled: token !== "",
  });
};

interface EducationVariables {
  type: string;
  start_date?: Date;
  end_date?: Date | null;
}

const useMutateEducation = (
  mutationKey: string,
  method: "POST" | "PATCH" | "DELETE"
) => {
  const queryClient = useQueryClient();
  const { token } = useAuth();
  const apiService = new ApiService(token);

  return useMutation({
    mutationKey: [mutationKey],
    mutationFn: async (postData: EducationVariables) => {
      let body: any = { type: postData.type };

      if (method !== "DELETE") {
        if (postData.start_date) {
          body.start_date = format(postData.start_date, "yyyy-MM-dd");
        }
        if (postData.end_date !== null && postData.end_date !== undefined) {
          body.end_date = format(postData.end_date, "yyyy-MM-dd");
        }
      }

      const options: RequestInit = {
        method,
        body: JSON.stringify(body),
      };

      return apiService.makeRequest("/api/v1/education", options);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["educations"] });
    },
  });
};

export const useAddEducationMutation = () =>
  useMutateEducation("addEducation", "POST");

export const usePatchEducationMutation = () =>
  useMutateEducation("mutateEducation", "PATCH");

export const useDeleteEducationMutation = () =>
  useMutateEducation("mutateEducation", "DELETE");
