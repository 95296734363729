import { useTheme } from "@mui/joy";
import { MockContainer, MockImage } from "./MockComponent";
import mockImage1 from "../../assets/images/mock/image1.png";
import mockImage2 from "../../assets/images/mock/image2.png";
import mockImage3 from "../../assets/images/mock/image3.png";
import mockImage4 from "../../assets/images/mock/image4.png";
import mockImage5 from "../../assets/images/mock/image5.png";
import mockImage6 from "../../assets/images/mock/image6.png";
import { useMediaQuery } from "@mui/material";

const Dashboard = () => {
  const theme = useTheme();

  /**@type {import("@mui/material").SxProps;} */
  const styles = {
    contentContainer: {
      display: "flex",
    },
    card: {
      maxWidth: "100%",
    },
    medicard: {
      maxWidth: "100%",
      display: "flex",
      bgcolor: theme.palette.primary[700],
      p: 0,
      m: 0,
    },
  };

  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <>
      <MockContainer>
        <MockImage src={mockImage1} maxWidth={isSmallScreen ? "100%" : 1020} />
        <MockImage src={mockImage6} maxWidth={isSmallScreen ? "100%" : 500} />
        <MockImage src={mockImage2} maxWidth={isSmallScreen ? "100%" : 500} />
        <MockImage src={mockImage4} maxWidth={isSmallScreen ? "100%" : 500} />
      </MockContainer>
    </>
  );
};

export default Dashboard;
