import IconButton from "@mui/joy/IconButton";
import { LightModeOutlined, DarkModeOutlined } from "@mui/icons-material";

import { useColorScheme as useMaterialColorScheme } from "@mui/material/styles";

import { useColorScheme as useJoyColorScheme } from "@mui/joy/styles";

export default function ModeToggle() {
  const { mode, setMode } = useMaterialColorScheme();
  const { setMode: setJoyMode } = useJoyColorScheme();

  return (
    <IconButton
      onClick={() => {
        setMode(mode === "dark" ? "light" : "dark");
        setJoyMode(mode === "dark" ? "light" : "dark");
      }}
    >
      {mode === "dark" ? <LightModeOutlined /> : <DarkModeOutlined />}
    </IconButton>
  );
}
