import { Stack, useTheme } from "@mui/joy";
import {
  useFetchProcedureDataQuery,
  useSaveProcedureMutation,
  ValidProcedureTypes,
} from "../../services/ProcedureService";
import {
  InputOptions,
  MediSkillCloseButton,
  MediSkillDatePicker,
  MediSKillRadioGroup,
  MediSkillSelect,
  MediSkillSlider,
  MediSkillSubmitButton,
  MediSkillTextarea,
  MediSkillTextInput,
  transformResponseToInputOptions,
} from "./MediSkillComponents";
import { useMediaQuery } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { Loading } from "../Loading";
import { MutationSuccess } from "../MutateSuccess";
import { MutationError } from "../MutateError";
import { format } from "date-fns";

interface MiniCexProcedureProps {
  closeAction: () => void;
}

export function MiniCexProcedure(props: MiniCexProcedureProps) {
  const { closeAction } = props;

  const [procedureDate, setProcedureDate] = useState<Date | null>(null);
  const [procedureSaved, setProcedureSaved] = useState<boolean>(false);
  const [isValid, setIsValid] = useState<boolean>(false);

  const [locality, setLocality] = useState<string>("");
  const [focus, setFocus] = useState<string>("");
  const [gender, setGender] = useState<string>("");
  const [age, setAge] = useState<number | "">("");
  const [diagnose, setDiagnose] = useState<string>("");
  const [satisfaction, setSatisfaction] = useState<number>(5);
  const [supervisor, setSupervisor] = useState<string>("");
  const [commentOwn, setCommentOwn] = useState<string>("");

  const [optionsLocality, setOptionsLocality] = useState<InputOptions[]>([]);
  const [optionsFocus, setOptionsFocus] = useState<InputOptions[]>([]);
  const [optionsGender, setOptionsGender] = useState<InputOptions[]>([]);
  const [optionsSupervisor, setOptionsSupervisor] = useState<InputOptions[]>(
    []
  );

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const procedureDataQuery = useFetchProcedureDataQuery("mini_cex");
  const saveProcedureMutation = useSaveProcedureMutation({
    onSuccessCallback: () => {
      setProcedureSaved(true);
    },
  });

  useEffect(() => {
    if (procedureDataQuery.data) {
      setOptionsLocality(
        transformResponseToInputOptions(
          procedureDataQuery.data["optionsLocality"]
        )
      );
      setOptionsFocus(
        transformResponseToInputOptions(procedureDataQuery.data["optionsFocus"])
      );
      setOptionsGender(
        transformResponseToInputOptions(
          procedureDataQuery.data["optionsGender"]
        )
      );

      setOptionsSupervisor(
        procedureDataQuery.data["optionsSupervisors"].map((supervisor: any) => {
          return {
            id: supervisor["id"],
            label: supervisor["full_name"],
          };
        })
      );
    }
  }, [procedureDataQuery.data]);

  const handleChangeCommentOwn = (
    event: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    setCommentOwn(event.target.value);
  };

  const handleAgeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    if (value === "" || /^\d+$/.test(value)) {
      setAge(value === "" ? "" : parseInt(value, 10));
    }
  };

  const validate = useCallback(() => {
    if (procedureDate === null) return false;
    if (supervisor === "") return false;
    if (locality === "") return false;
    if (gender === "") return false;
    if (age === "") return false;
    if (diagnose === "") return false;
    return true;
  }, [procedureDate, supervisor, locality, gender, age, diagnose]);

  useEffect(() => {
    setIsValid(validate());
  }, [validate]);

  const handleSubmit = async function () {
    const metadata: Record<string, any> = {};

    if (procedureDate)
      metadata.procedureDate = format(procedureDate, "yyyy-MM-dd");
    if (supervisor) metadata.supervisor = supervisor;
    if (commentOwn) metadata.commentOwn = commentOwn;

    if (locality) metadata.locality = locality;
    if (focus) metadata.focus = focus;
    if (gender) metadata.gender = gender;
    if (age) metadata.age = age;
    if (diagnose) metadata.diagnose = diagnose;
    if (satisfaction) metadata.satisfaction = satisfaction;

    if (procedureDate) {
      saveProcedureMutation.mutate({
        date: procedureDate,
        procedure_type: ValidProcedureTypes.MiniCex,
        metadata: metadata,
      });
    }
  };

  if (procedureDataQuery.isLoading) {
    return <Loading />;
  }

  return (
    <Stack spacing={3}>
      <MediSkillDatePicker
        label="Dato for udførelsen:"
        direction={isSmallScreen ? "column" : "row"}
        onChange={(newValue) => setProcedureDate(newValue)}
        required={procedureDate === null}
        procedureDate={procedureDate}
        disabled={procedureSaved}
      />

      <MediSkillSelect
        label="Hvem superviserede dig?"
        handleChange={(event: React.SyntheticEvent | null) => {
          const target = event?.target as HTMLElement;
          setSupervisor(target.getAttribute("data-key") ?? "");
        }}
        options={optionsSupervisor}
        direction={isSmallScreen ? "column" : "row"}
        required={supervisor === ""}
        disabled={procedureSaved}
      />

      <MediSKillRadioGroup
        label="Lokaliteter?"
        groupName="locality"
        handleChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          setLocality(event.target.getAttribute("data-key") ?? "");
        }}
        selectedValue={locality}
        options={optionsLocality}
        direction={isSmallScreen ? "column" : "row"}
        required={locality === ""}
        disabled={procedureSaved}
      />

      <MediSKillRadioGroup
        label="Patient?"
        groupName="Gender"
        handleChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          setGender(event.target.getAttribute("data-key") ?? "");
        }}
        selectedValue={gender}
        options={optionsGender}
        direction={isSmallScreen ? "column" : "row"}
        required={gender === ""}
        showDivider={true}
        disabled={procedureSaved}
      />

      <MediSkillTextInput
        handleChange={handleAgeChange}
        label="Alder (i hele år):"
        selectedValue={age as string}
        direction={isSmallScreen ? "column" : "row"}
        required={age === ""}
        disabled={procedureSaved}
      />

      <MediSkillTextarea
        label="Patientproblem/diagnose"
        handleChange={(event: React.ChangeEvent<HTMLTextAreaElement>) => {
          setDiagnose(event.target.value);
        }}
        direction={isSmallScreen ? "column" : "row"}
        required={diagnose === ""}
        disabled={procedureSaved}
      />

      <MediSKillRadioGroup
        label="Fokus?"
        groupName="focus"
        handleChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          setFocus(event.target.getAttribute("data-key") ?? "");
        }}
        selectedValue={focus}
        options={optionsFocus}
        direction={isSmallScreen ? "column" : "row"}
        required={focus === ""}
        disabled={procedureSaved}
      />

      <MediSkillSlider
        handleChange={(event: Event, newValue: number | number[]) => {
          setSatisfaction(newValue as number);
        }}
        label=" Uddannelsessøgendes tilfredshed med Mini-CEX"
        min={1}
        max={9}
        labelMid={String(satisfaction)}
        selectedValue={satisfaction}
        disabled={procedureSaved}
      />

      <MediSkillTextarea
        label="Egne bemærkninger til operationen (vises kun til dig)"
        handleChange={handleChangeCommentOwn}
        disabled={procedureSaved}
      />

      {procedureSaved && (
        <>
          <MutationSuccess message="Procedure gemt" />
          <MediSkillCloseButton closeAction={closeAction} />
        </>
      )}

      {saveProcedureMutation.error && (
        <MutationError message={saveProcedureMutation.error.message} />
      )}

      {!procedureSaved && (
        <MediSkillSubmitButton
          label="Gem procedure"
          handleSubmit={handleSubmit}
          disabled={!isValid}
          loading={saveProcedureMutation.isPending}
        />
      )}
    </Stack>
  );
}
