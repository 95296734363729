import { Table, Tooltip, Typography, useTheme } from "@mui/joy";
import { TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import { Loading } from "../Loading";
import { Error } from "../Error";
import { EnhancedTableToolbar } from "../EnhancedToolbar";
import { User } from "../../services/UserService";

interface UserSummaryProps {
  users: User[] | undefined;
  title: string;

  /**
   * Boolean indicating whether the data is currently being loaded.
   */
  loading: boolean;

  /**
   * An error object containing error details if there is an error in loading data.
   */
  error: Error | null;
}

export function UserSummary(props: UserSummaryProps) {
  const { users, title, loading, error } = props;

  const theme = useTheme();

  if (loading) {
    return <Loading />;
  }

  if (error) {
    return <Error message={error.message} />;
  }

  // Roles to match
  const matchingRoles = ["user-manager", "student", "supervisor"];

  // Initialize the count and display name objects
  const roleCounts: {
    [key: string]: { count: number; name: string; description: string };
  } = {
    student: { count: 0, name: "", description: "" },
    "user-manager": { count: 0, name: "", description: "" },
    supervisor: { count: 0, name: "", description: "" },
  };

  // Count users with each matching role
  users?.forEach((user) => {
    user.roles.forEach((role) => {
      if (matchingRoles.includes(role.key)) {
        roleCounts[role.key].count++;
        roleCounts[role.key].name = role.name;
        roleCounts[role.key].description = role.description;
      }
    });
  });

  return (
    <>
      <EnhancedTableToolbar title={title} />
      <Table>
        <TableHead>
          <TableRow>
            <TableCell
              key="type"
              sx={{
                width: "70%",
                backgroundColor: `${theme.palette.primary[700]} !important`,
              }}
            >
              Roller
            </TableCell>
            <TableCell
              key="number"
              sx={{
                backgroundColor: `${theme.palette.primary[700]} !important`,
              }}
            >
              Antal
            </TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {Object.entries(roleCounts).map(([role, count]) => (
            <Tooltip
              key={role}
              title={count.description}
              enterDelay={1000}
              enterNextDelay={1000}
            >
              <TableRow key={role}>
                <TableCell>{count.name}</TableCell>
                <TableCell>{count.count}</TableCell>
              </TableRow>
            </Tooltip>
          ))}
          <Tooltip
            key="total"
            title="OBS: Den samme bruger kan have flere roller"
            enterDelay={1000}
            enterNextDelay={1000}
          >
            <TableRow
              key="total"
              sx={{ backgroundColor: theme.palette.primary[50] }}
            >
              <TableCell>Total antal brugere</TableCell>
              <TableCell>{users?.length}</TableCell>
            </TableRow>
          </Tooltip>
        </TableBody>
      </Table>
    </>
  );
}
