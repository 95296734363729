import { Box } from "@mui/material";
import { VascularSugeryIcon } from "../Icons/VasuclarSugeryIcon";
import { MiniCexIcon } from "../Icons/MiniCexIcon";

export function NewProcedure() {
  return (
    <>
      <Box>NewProcedure</Box>
      <VascularSugeryIcon />
      <MiniCexIcon />
    </>
  );
}
