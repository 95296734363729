import { Box, Card } from "@mui/joy";
import { GanttChart } from "./GanttChart";
import { useEffect, useState } from "react";
import { Loading } from "../Loading";
import { GenericTable } from "../GenericTable";
import { ModalWrapper } from "../ModalWrapper";
import { EducationCreate } from "./EducationCreate";
import {
  Education,
  EducationTypes,
  translateEducationKeyToValue,
  useFetchEducationsQuery,
  useEducationTypesQuery,
} from "../../services/EducationService";
import { EducationMutate } from "./EducationMutate";

export function Profile() {
  const queryEducationTypes = useEducationTypesQuery();
  const queryEducation = useFetchEducationsQuery();

  useEffect(() => {
    setEducationTypesAvailable(
      filterAvailabletypes(
        queryEducationTypes?.data ?? {},
        queryEducation?.data ?? []
      )
    );
  }, [queryEducation?.data, queryEducationTypes?.data]);

  const [educationTypesAvailable, setEducationTypesAvailable] =
    useState<EducationTypes | null>(null);

  const filterAvailabletypes = (
    educationTypes: EducationTypes,
    data: Array<Education>
  ): EducationTypes => {
    const availableTypes: EducationTypes = {};

    // Iterate over the keys of the educationTypes object
    for (const key in educationTypes) {
      if (educationTypes.hasOwnProperty(key)) {
        // Check if any Education item has the current type key
        const found = data.some((current) => current.type === key);

        // If the type is not found in the data, add it to availableTypes
        if (!found) {
          availableTypes[key] = educationTypes[key];
        }
      }
    }

    return availableTypes;
  };

  const columns = [
    {
      id: "type",
      label: "Type",
      output: (education: Education) =>
        translateEducationKeyToValue(
          queryEducationTypes?.data ?? {},
          education.type
        ),
    },
    {
      id: "start",
      label: "Start",
      output: (education: Education) =>
        education.start_date.toLocaleDateString("en-CA"),
    },
    {
      id: "end",
      label: "Slut",
      output: (education: Education) =>
        education.end_date?.toLocaleDateString("en-CA"),
    },
  ];

  if (!queryEducation || queryEducation.isLoading) {
    return <Loading />;
  }
  return (
    <>
      {queryEducation.data && (
        <Card sx={{ maxWidth: "800px" }}>
          <Box
            sx={{
              display: "flex",
              gap: 2,
              flexDirection: { xs: "column", md: "row" }, // Stacks vertically on small screens, horizontally on medium and up
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                maxWidth: { xs: "100%", md: "40%" }, // Full width on small screens, fixed width on medium and up
                marginBottom: { xs: 2, md: 0 }, // Adds space between items when stacked
              }}
            >
              <GenericTable
                data={queryEducation.data}
                loading={queryEducation.isError}
                error={queryEducation.error}
                columns={columns}
                itemsSelector={(data: Education[]): Education[] => data}
                idSelector={(row: Education) => row.id}
                title="Uddannelsesforløb"
                createComponent={(open, setOpen) => {
                  return (
                    <ModalWrapper
                      modalTitle="Tilføj uddannelsesforløb"
                      open={open}
                      setOpen={setOpen}
                      children={(close) => (
                        <EducationCreate
                          closeAction={close}
                          options={educationTypesAvailable ?? {}}
                        />
                      )}
                    />
                  );
                }}
                updateComponent={(open, setOpen, entity) => {
                  return (
                    <ModalWrapper
                      modalTitle={"Rediger uddannelsesforløb"}
                      open={open}
                      setOpen={setOpen}
                      children={(close) => (
                        <EducationMutate
                          closeAction={close}
                          options={queryEducationTypes?.data ?? {}}
                          education={entity as Education}
                        />
                      )}
                    />
                  );
                }}
              />
            </Box>
            {queryEducation.data.length > 0 && (
              <Box
                sx={{
                  width: { xs: "100%", md: "60%" }, // Full width on small screens, fixed width on medium and up
                  marginTop: { xs: 1, md: 8 },
                }}
              >
                <GanttChart
                  educationTypes={queryEducationTypes?.data ?? {}}
                  educations={queryEducation.data}
                />
              </Box>
            )}
          </Box>
        </Card>
      )}
    </>
  );
}
