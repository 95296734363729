import { ApiService } from "./ApiService";
import { format } from "date-fns";
import { useAuth } from "./AuthProvider";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

export enum ValidProcedureTypes {
  VascularSugery = "vascular_sugery",
  VascularUltrasound = "vascular_ultrasound",
  MiniCex = "mini_cex",
}

export interface ProcedureData {
  data: Procedure[];
  status: string;
  error: Error | null;
  message: string;
}

export class Procedure {
  id: string;
  date: Date;
  user_id: string;
  supervisor_id: string | null;
  procedure_type: string;
  metadata: string;
  average_score: number;

  constructor(procedure: {
    id: string;
    date: Date;
    user_id: string;
    supervisor_id: string | null;
    procedure_type: string;
    metadata: string;
    average_score: number;
  }) {
    this.id = procedure.id;
    this.date = procedure.date;
    this.user_id = procedure.user_id;
    this.supervisor_id = procedure.supervisor_id;
    this.procedure_type = procedure.procedure_type;
    this.metadata = procedure.metadata;
    this.average_score = procedure.average_score;
  }
}

export const useFetchProcedureDataQuery = (type: string) => {
  const { token } = useAuth();
  const apiService = new ApiService(token);

  return useQuery({
    queryKey: [type],
    queryFn: async () => {
      return await apiService.makeRequest<any>(
        "/api/v1/procedures/data/" + type
      );
    },
    enabled: token !== "",
  });
};

export const useFetchAllProceduresQuery = () => {
  const { token } = useAuth();
  const apiService = new ApiService(token);
  return useQuery({
    queryKey: ["procedures"],
    queryFn: async () => {
      return await apiService.makeRequest<Procedure[]>("/api/v1/procedures");
    },
    enabled: token !== "",
  });
};

interface SaveProcedureMutationVariables {
  procedure_type: string;
  date: Date;
  metadata: Record<string, string>;
}
export const useSaveProcedureMutation = ({
  onSuccessCallback,
}: { onSuccessCallback?: () => void } = {}) => {
  const queryClient = useQueryClient();
  const { token } = useAuth();
  const apiService = new ApiService(token);

  return useMutation({
    mutationKey: ["savemutation"],
    mutationFn: async (postData: SaveProcedureMutationVariables) => {
      const body: any = {
        procedure_type: postData.procedure_type,
        date: format(postData.date, "yyyy-MM-dd"),
        metadata: {
          ...postData.metadata,
        },
      };

      const options: RequestInit = {
        method: "POST",
        body: JSON.stringify(body),
      };

      return apiService.makeRequest("/api/v1/procedures", options);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["educations"] });
      if (onSuccessCallback) {
        onSuccessCallback();
      }
    },
  });
};
