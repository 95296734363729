import { createBrowserRouter, RouterProvider } from "react-router-dom";
import {
  experimental_extendTheme as materialExtendTheme,
  Experimental_CssVarsProvider as MaterialCssVarsProvider,
  THEME_ID as MATERIAL_THEME_ID,
} from "@mui/material/styles";
import {
  getInitColorSchemeScript,
  CssVarsProvider as JoyCssVarsProvider,
} from "@mui/joy/styles";
import CssBaseline from "@mui/material/CssBaseline";

import Dashboard from "./components/Dashboard/Dashboard";
import MyProcedures from "./components/MyProcedures/MyProcedures";
import Layout from "./components/App/Layout";

import { NewProcedure } from "./components/NewProcedure/NewProcedure";
import NotFound from "./components/Pages/NotFound";
import ProtectedRoute from "./components/Routes/ProtectedRoute";
import UserAdminRoute from "./components/Routes/UserAdminRoute";
import { theme } from "./config/theme";
import StudentView from "./components/StudentView/StudentView";
import { Profile } from "./components/Profile/Profile";
import SupervisorRoute from "./components/Routes/SupervisorRoute";

const materialTheme = materialExtendTheme();

getInitColorSchemeScript({ defaultMode: "light" });

export function Root() {
  const router = createBrowserRouter([
    {
      path: "/",
      element: <ProtectedRoute />,
      errorElement: <ProtectedRoute />,
      children: [
        {
          element: <Layout />,
          path: "/",
          children: [
            {
              path: "/",
              element: <Dashboard />,
            },
            {
              path: "profile/",
              element: <Profile />,
            },
            {
              path: "myprocedures/",
              element: <MyProcedures />,
            },
            {
              path: "studentview/",
              element: <StudentView />,
            },
            {
              path: "newprocedure/",
              element: <NewProcedure />,
            },
            {
              path: "*",
              element: <NotFound />,
            },
            {
              path: "useradmin/",
              element: <UserAdminRoute />,
            },
            {
              path: "supervisor/",
              element: <SupervisorRoute />,
            },
          ],
        },
      ],
    },
  ]);

  return (
    <JoyCssVarsProvider defaultMode="system" theme={theme}>
      <MaterialCssVarsProvider theme={{ [MATERIAL_THEME_ID]: materialTheme }}>
        <CssBaseline enableColorScheme />
        <RouterProvider router={router} />
      </MaterialCssVarsProvider>
    </JoyCssVarsProvider>
  );
}
