import {
  Box,
  Button,
  Card,
  FormControl,
  FormLabel,
  Select,
  Stack,
  useTheme,
  Option,
  FormHelperText,
} from "@mui/joy";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { useEffect, useState } from "react";
import { ErrorOutlineOutlined } from "@mui/icons-material";
import {
  EducationTypes,
  useAddEducationMutation,
} from "../../services/EducationService";
import { MutationError } from "../MutateError";
import { MutationSuccess } from "../MutateSuccess";

interface EducationCreateProps {
  options: EducationTypes;

  closeAction: () => void;
}

export function EducationCreate(props: EducationCreateProps) {
  const { options, closeAction } = props;
  const [type, setType] = useState<string>("");
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [valid, setValid] = useState<boolean>(false);
  const [dateValid, setDateValid] = useState<boolean>(false);

  useEffect(() => {
    const isDateValid =
      startDate !== null && (endDate === null || startDate < endDate);

    setDateValid(isDateValid);
    setValid(type !== "" && isDateValid);
  }, [type, startDate, endDate]);

  const theme = useTheme();

  const mutation = useAddEducationMutation();

  const handleSubmit = () => {
    if (type !== "" && startDate) {
      mutation.mutate({ type: type, start_date: startDate, end_date: endDate });
    }
  };

  return (
    <Box sx={{ flexGrow: "1", display: "flex", flexDirection: "column" }}>
      <Card sx={{ flexGrow: 1 }}>
        <Stack spacing={3}>
          <FormControl sx={{ marginLeft: "10px", marginRight: "10px" }}>
            <FormLabel
              sx={{ color: theme.vars.palette.text.tertiary }}
              component="legend"
            >
              Type
            </FormLabel>
            <Select
              placeholder="Vælg uddannelsestype"
              onChange={(
                event: React.SyntheticEvent | null,
                newValue: string | null
              ) => {
                setType(newValue ?? "");
              }}
              disabled={mutation.isSuccess}
            >
              {options &&
                Object.keys(options).map((key) => (
                  <Option key={key} value={key}>
                    {options[key]}
                  </Option>
                ))}
            </Select>
          </FormControl>
          <Box sx={{ display: "flex", gap: 2 }}>
            <FormControl sx={{ width: "50%" }}>
              <Box>
                <DatePicker
                  label="Start dato*"
                  slotProps={{ textField: { size: "small" } }}
                  onChange={(newValue) => setStartDate(newValue)}
                  disabled={mutation.isSuccess}
                />
              </Box>
            </FormControl>
            <FormControl sx={{ width: "50%" }}>
              <Box>
                <DatePicker
                  label="Slut dato"
                  slotProps={{ textField: { size: "small" } }}
                  onChange={(newValue) => setEndDate(newValue)}
                  disabled={mutation.isSuccess}
                />
              </Box>
            </FormControl>
          </Box>
          <Box>
            {!dateValid && startDate && endDate && (
              <FormHelperText
                sx={{
                  color: theme.palette.danger[700],
                  fontStyle: "italic",
                }}
              >
                <ErrorOutlineOutlined />
                Slut dato skal være efter end start dato
              </FormHelperText>
            )}
          </Box>
        </Stack>

        {mutation.error && <MutationError message={mutation.error.message} />}
        {mutation.isSuccess && (
          <>
            <MutationSuccess message="Uddannelsesforløb tilføjet" />

            <Button
              role="submit"
              onClick={closeAction}
              sx={{
                margin: "20px 0px",
                width: "100%",
                backgroundColor: theme.palette.primary[700],
                "&:hover": {
                  backgroundColor: theme.palette.primary[800],
                },
              }}
            >
              Luk
            </Button>
          </>
        )}

        {!mutation.isSuccess && (
          <Button
            role="submit"
            sx={{
              margin: "20px 0px",
              width: "100%",
              backgroundColor: theme.palette.primary[700],
              "&:hover": {
                backgroundColor: theme.palette.primary[800],
              },
            }}
            loading={mutation.isPending}
            disabled={!valid || mutation.isPending}
            onClick={handleSubmit}
          >
            Tilføj
          </Button>
        )}
      </Card>
    </Box>
  );
}
