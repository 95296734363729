import { Box, useTheme } from "@mui/joy";
import { Warning } from "@mui/icons-material";

/**
 * Props for the MutationError component.
 */
interface MutationErrorProps {
  /**
   * The error message to be displayed.
   */
  message: string;
}

/**
 * Component for displaying an error message after a mutation action.
 *
 * @param props - Props for the MutationError component.
 * @returns A component for displaying the error message.
 */
export function MutationError({ message }: MutationErrorProps) {
  const theme = useTheme();
  return (
    <Box
      sx={{
        margin: "50px 5px 20px",
        display: "flex",
        justifyContent: "center",
        color: theme.palette.warning[600],
        fontWeight: theme.fontWeight.md,
        alignItems: "center",
      }}
    >
      <Warning sx={{ fontSize: "2em", marginRight: "15px" }} />
      <>{message}</>
    </Box>
  );
}
