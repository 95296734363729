export class ApiService {
  private accessToken: string = "";

  constructor(accessToken: string) {
    this.accessToken = accessToken;
  }

  public async makeRequest<T>(
    endpoint: string,
    options: RequestInit = {}
  ): Promise<T> {
    const endpoint_base = process.env.REACT_APP_API_ENDPOINT_BASE;

    const response = await fetch(endpoint_base + endpoint, {
      ...options,
      headers: {
        Authorization: `Bearer ${this.accessToken}`,
        "Content-Type": "application/json",
        ...options.headers,
      },
    });

    // Check if the response is not successful (status code outside the 200-299 range)
    if (!response.ok) {
      // Get the error message from the response
      const errorData = await response.json();
      // Throw an error with the status and the error message
      throw new Error(errorData.error || "Something went wrong");
    }

    return response.json() as Promise<T>;
  }
}
