import { ProcedureIcon } from "./ProcedureIcon";
import vascularSugeryIcon from "../../assets/images/icons/vascular_sugery_icon.png";
import { useState } from "react";
import { ModalWrapper } from "../ModalWrapper";
import { VascularSugeryProcedure } from "../NewProcedure/VascularSugeryProcedure";

interface VascularSugeryIconProps {
  size?: number;
}

export function VascularSugeryIcon(props: VascularSugeryIconProps) {
  const { size } = props;
  const [open, setOpen] = useState(false);

  const handleIconClick = () => {
    setOpen(true);
  };

  return (
    <>
      <ProcedureIcon
        src={vascularSugeryIcon}
        size={size}
        onClick={handleIconClick}
      />

      {open && (
        <ModalWrapper
          modalTitle="Tilføj karkirurgisk procedure"
          open={open}
          setOpen={setOpen}
          children={(close) => <VascularSugeryProcedure closeAction={close} />}
        />
      )}
    </>
  );
}
