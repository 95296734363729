import logo from "../assets/images/logo.png";
import { Box } from "@mui/joy";

const Logo = ({ sx }: { sx?: object }) => {
  return (
    <Box sx={{ ...styles.logoContainer, ...sx }}>
      <Box component="img" sx={styles.logo} src={logo}></Box>
    </Box>
  );
};

/**@type {import("@mui/joy").SxProps;} */
const styles = {
  logoContainer: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  logo: {
    width: "100%",
  },
};

export default Logo;
