import { Box } from "@mui/material";
import {
  Procedure,
  useFetchAllProceduresQuery,
} from "../../services/ProcedureService";
import { Card, Stack } from "@mui/joy";
import { GenericTable } from "../GenericTable";

export default function MyProcedures() {
  const { data: procedures, isLoading, error } = useFetchAllProceduresQuery();

  const columns = [
    {
      id: "date",
      label: "Dato",
      output: (row: Procedure) => row.date,
      sx: {
        width: "100px",
      },
    },
    {
      id: "type",
      label: "Type",
      output: (row: Procedure) => row.procedure_type,
      sx: {
        width: "200px",
      },
    },
    {
      id: "metadata",
      label: "Metadata",
      output: (row: Procedure) =>
        JSON.stringify(JSON.parse(row.metadata), null, 2),
      sx: {
        width: "auto",
      },
    },
  ];

  return (
    <Stack spacing={2}>
      <Box>MyProcedures</Box>
      <Card>
        <GenericTable
          title="Mine procedurer"
          columns={columns}
          data={procedures ?? []}
          error={error}
          itemsSelector={(data: Procedure[]): Procedure[] => data}
          idSelector={(row: Procedure) => row.id}
          loading={isLoading}
        />
      </Card>
    </Stack>
  );
}
