import { extendTheme } from "@mui/joy";

export const theme = extendTheme({
  fontFamily: {
    display: [
      "Poppins",
      "Be Vietnam Pro",
      "Verdana",
      "Helvetica",
      "Arial",
      "sans-serif",
    ].join(","),
    body: [
      "Poppins",
      "Be Vietnam Pro",
      "Verdana",
      "Helvetica",
      "Arial",
      "sans-serif",
    ].join(","),
  },
  colorSchemes: {
    light: {
      palette: {
        primary: {
          50: "#dcf3f6",
          100: "#b5d6e0",
          200: "#91bacc",
          300: "#719eb9",
          400: "#5383a6",
          500: "#376894",
          600: "#1e4d81",
          700: "#03336d",
          800: "#032653",
          900: "#031a3a",
        },
        background: {
          body: "#121212",
        },
        text: {
          primary: "#031a3a",
          secondary: "#dcf3f6",
        },
      },
    },
    dark: {
      palette: {
        primary: {
          50: "#031a3a",
          100: "#032653",
          200: "#03336d",
          300: "#1e4d81",
          400: "#376894",
          500: "#5383a6",
          600: "#719eb9",
          700: "#91bacc",
          800: "#b5d6e0",
          900: "#dcf3f6",
        },
        background: {
          body: "#121212",
        },
        text: {
          primary: "#dcf3f6",
          secondary: "#031a3a",
        },
      },
    },
  },
});
