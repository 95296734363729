import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { ApiService } from "./ApiService";
import { useAuth } from "./AuthProvider";

export interface OrganizationDetails {
  code: string;
  logo: string;
  name: string;
  is_default: boolean;
}

export interface UserData {
  code: string;
  message: string;
  next_token: string;
  organization_users: User[];
  organization_details: OrganizationDetails;
}

export class User {
  email: string;
  first_name: string;
  full_name: string;
  id: string;
  last_name: string;
  picture: string;
  roles: Role[];

  constructor(user: {
    email: string;
    first_name: string;
    full_name: string;
    id: string;
    last_name: string;
    picture: string;
    roles: Role[];
  }) {
    this.email = user.email;
    this.first_name = user.first_name;
    this.full_name = user.full_name;
    this.id = user.id;
    this.last_name = user.last_name;
    this.picture = user.picture;
    this.roles = user.roles;
  }
}

export class UserWithProcedureCount extends User {
  procedure_count: number;

  constructor(user: {
    email: string;
    first_name: string;
    full_name: string;
    id: string;
    last_name: string;
    picture: string;
    roles: Role[];
    procedure_count: number;
  }) {
    super(user); // Call the parent class (User) constructor
    this.procedure_count = user.procedure_count;
  }
}

export class Role {
  id: string;
  key: string;
  name: string;
  description: string;
  requirement: string;

  constructor(
    id: string,
    key: string,
    name: string,
    description: string,
    requirement: string
  ) {
    this.id = id;
    this.key = key;
    this.name = name;
    this.description = description;
    this.requirement = requirement;
  }
}

export class KindeRoles {
  public static roles: Role[] = [
    new Role(
      "018fb4f4-0e98-21a3-84e8-2b5a689883a9",
      "user-manager",
      "Brugeradministrator",
      "Kan tilføje og fjerne brugere til sin egen organisation",
      "role:grant-useradmin"
    ),
    new Role(
      "01900926-f0c7-1759-e338-7a75cc922bf1",
      "student",
      "Kursist",
      "Uddannelsessøgende læge, kan logge og se sine egne procedurer",
      "role:grant-student"
    ),
    new Role(
      "018f7e35-e392-36ea-b068-19f4b8fcfcfb",
      "mediskill-admin",
      "MediSkill-administrator",
      "Højeste rolle, har alle rettigheder",
      "role:grant-mediskilladmin"
    ),
    new Role(
      "01900928-50a9-f8e8-0632-f749cf215305",
      "supervisor",
      "Supervisor",
      "Supervisor for uddannelsessøgende. Kan bedømme procedurer",
      "role:grant-supervisor"
    ),
  ];

  public static getRoleByKey(key: string): Role | null {
    return this.roles.find((role) => role.key === key) || null;
  }
}

export enum KindeRoleKey {
  UserManager = "user-manager",
  Student = "student",
  MediSkillAdmin = "mediskill-admin",
  Supervisor = "supervisor",
}

export const useFetchusersQuery = () => {
  const { token } = useAuth();
  const apiService = new ApiService(token);

  return useQuery({
    queryKey: ["users"],
    queryFn: async () => {
      return await apiService.makeRequest<UserData>("/api/v1/users");
    },
    enabled: token !== "",
  });
};

export const useSearchUserByEmailQuery = (email: string, enabled: boolean) => {
  const { token } = useAuth();
  const apiService = new ApiService(token);
  return useQuery({
    queryKey: ["userSearch", email],
    queryFn: async () => {
      const result = await apiService.makeRequest<User>(
        `/api/v1/usersearch?email=${email}`
      );

      return "id" in result ? result : null;
    },
    enabled: token !== "" && enabled,
  });
};

interface UseCreateUserQueryOptions {
  onSuccessCallback?: () => void;
}
interface AddUserVariables {
  first_name: string;
  last_name: string;
  email: string;
}
export const useCreateUserQuery = ({
  onSuccessCallback,
}: UseCreateUserQueryOptions = {}) => {
  const { token } = useAuth();
  const apiService = new ApiService(token);
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: ["addUserMutation"],
    mutationFn: async (postData: AddUserVariables) => {
      const options: RequestInit = {
        method: "POST",
        body: JSON.stringify({
          first_name: postData.first_name,
          last_name: postData.last_name,
          email: postData.email,
        }),
      };

      return apiService.makeRequest("/api/v1/users", options);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["users"] });
      if (onSuccessCallback) {
        onSuccessCallback();
      }
    },
  });
};

interface UseMutateOrgQueryOptions {
  onSuccessCallback?: () => void;
}

interface UserOrgMutationVariables {
  userId: string;
}

const useMutateOrgQuery = (
  action: "add" | "remove",
  { onSuccessCallback }: UseMutateOrgQueryOptions = {}
) => {
  const { token } = useAuth();
  const apiService = new ApiService(token);
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ["userOrgMutation", action],
    mutationFn: async (postData: UserOrgMutationVariables) => {
      const options: RequestInit = {
        method: "PATCH",
        body: JSON.stringify({
          op: `org-${action}`,
        }),
      };

      return apiService.makeRequest(
        `/api/v1/users/${postData.userId}`,
        options
      );
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["users"] });
      if (onSuccessCallback) {
        onSuccessCallback();
      }
    },
  });
};

export const useAddUserToOrgMutation = (
  options: UseMutateOrgQueryOptions = {}
) => useMutateOrgQuery("add", options);

export const useRemoveUserFromOrgMutation = (
  options: UseMutateOrgQueryOptions = {}
) => useMutateOrgQuery("remove", options);

interface UseMutateRoleQueryOptions {
  onSuccessCallback?: () => void;
}

interface UserRoleMutationVariables {
  userId: string;
  role: string;
}

const useMutateRoleQuery = (
  action: "add" | "remove",
  { onSuccessCallback }: UseMutateRoleQueryOptions = {}
) => {
  const { token } = useAuth();
  const apiService = new ApiService(token);
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ["userRoleMutation", action],
    mutationFn: async (postData: UserRoleMutationVariables) => {
      const options: RequestInit = {
        method: "PATCH",
        body: JSON.stringify({
          op: `role-${action}`,
          role: postData.role,
        }),
      };

      return apiService.makeRequest(
        `/api/v1/users/${postData.userId}`,
        options
      );
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["users"] });
      if (onSuccessCallback) {
        onSuccessCallback();
      }
    },
  });
};

export const useAddUserRoleMutation = (
  options: UseMutateRoleQueryOptions = {}
) => useMutateRoleQuery("add", options);

export const useRemoveUserRoleMutation = (
  options: UseMutateRoleQueryOptions = {}
) => useMutateRoleQuery("remove", options);
