import { Stack, useTheme } from "@mui/joy";
import { useMediaQuery } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import {
  InputOptions,
  MediSkillCheckbox,
  MediSkillCloseButton,
  MediSkillDatePicker,
  MediSKillRadioGroup,
  MediSkillSelect,
  MediSkillSubmitButton,
  MediSkillTextarea,
  transformResponseToInputOptions,
} from "./MediSkillComponents";
import {
  useFetchProcedureDataQuery,
  ValidProcedureTypes,
  useSaveProcedureMutation,
} from "../../services/ProcedureService";
import { Loading } from "../Loading";
import { MutationSuccess } from "../MutateSuccess";
import { MutationError } from "../MutateError";
import { format } from "date-fns";

interface VascularSugeryProcedureProps {
  closeAction: () => void;
}

export function VascularSugeryProcedure(props: VascularSugeryProcedureProps) {
  const { closeAction } = props;
  const [procedureDate, setProcedureDate] = useState<Date | null>(null);
  const [procedureSaved, setProcedureSaved] = useState<boolean>(false);

  const [live, setLive] = useState<string>("live");
  const [type, setType] = useState<string>("");
  const [role, setRole] = useState<string>("");
  const [aaa, setAAA] = useState<string>("");
  const [supervisor, setSupervisor] = useState<string>("");
  const [optionsSupervisor, setOptionsSupervisor] = useState<InputOptions[]>(
    []
  );

  const [commentOwn, setCommentOwn] = useState<string>("");
  const [commentSupervisor, setCommentSupervisor] = useState<string>("");
  const [assistantParts, setAssistantParts] = useState<string[]>([]);
  const [simulationParts, setSimulationParts] = useState<string[]>([]);

  const [showAAA, setShowAAA] = useState<boolean>(false);
  const [showSimulation, setShowSimulation] = useState<boolean>(false);
  const [showType, setShowType] = useState<boolean>(false);
  const [showRole, setShowRole] = useState<boolean>(false);
  const [showSupervisor, setShowSupervisor] = useState<boolean>(false);
  const [showAssistantPart, setShowAssistantPart] = useState<boolean>(false);

  const [isValid, setIsValid] = useState<boolean>(false);

  const [optionsLive, setOptionsLive] = useState<InputOptions[]>([]);
  const [optionsSimulation, setOptionsSimulation] = useState<InputOptions[]>(
    []
  );

  const [optionsType, setOptionsType] = useState<InputOptions[]>([]);
  const [optionsRole, setOptionsRole] = useState<InputOptions[]>([]);
  const [optionsAAA, setOptionsAAA] = useState<InputOptions[]>([]);
  const [optionsAssistantPart, setOptionsAssistantPart] = useState<
    InputOptions[]
  >([]);

  const procedureDataQuery = useFetchProcedureDataQuery("vascular_sugery");
  const saveProcedureMutation = useSaveProcedureMutation({
    onSuccessCallback: () => {
      setProcedureSaved(true);
    },
  });

  useEffect(() => {
    if (procedureDataQuery.data) {
      setOptionsLive(
        transformResponseToInputOptions(procedureDataQuery.data["optionsLive"])
      );
      setOptionsSimulation(
        transformResponseToInputOptions(
          procedureDataQuery.data["optionsSimulation"]
        )
      );
      setOptionsType(
        transformResponseToInputOptions(procedureDataQuery.data["optionsType"])
      );
      setOptionsRole(
        transformResponseToInputOptions(procedureDataQuery.data["optionsRole"])
      );
      setOptionsAAA(
        transformResponseToInputOptions(procedureDataQuery.data["optionsAAA"])
      );
      setOptionsAssistantPart(
        transformResponseToInputOptions(
          procedureDataQuery.data["optionsAssistantPart"]
        )
      );

      setOptionsSupervisor(
        procedureDataQuery.data["optionsSupervisors"].map((supervisor: any) => {
          return {
            id: supervisor["id"],
            label: supervisor["full_name"],
          };
        })
      );
    }
  }, [procedureDataQuery.data]);

  const handleSubmit = async function () {
    const metadata: Record<string, any> = {};

    if (procedureDate) metadata.procedureDate = format(procedureDate, "yyyy-MM-dd");;
    if (live) metadata.live = live;
    if (type) metadata.type = type;
    if (role) metadata.role = role;
    if (aaa) metadata.aaa = aaa;
    if (supervisor) metadata.supervisor = supervisor;
    if (simulationParts && simulationParts.length > 0)
      metadata.simulationParts = simulationParts.join(", ");
    if (assistantParts && assistantParts.length > 0)
      metadata.assistantParts = assistantParts.join(", ");
    if (commentOwn) metadata.commentOwn = commentOwn;
    if (commentSupervisor) metadata.commentSupervisor = commentSupervisor;

    if (procedureDate) {
      saveProcedureMutation.mutate({
        date: procedureDate,
        procedure_type: ValidProcedureTypes.VascularSugery,
        metadata: metadata,
      });
    }
  };

  const validate = useCallback(() => {
    if (procedureDate === null) return false;
    if (live === "") return false;
    if (showType && type === "") return false;
    if (showRole && role === "") return false;
    if (showAAA && aaa === "") return false;
    if (showSupervisor && supervisor === "") return false;
    if (showSimulation && simulationParts.length === 0) return false;
    if (showAssistantPart && assistantParts.length === 0) return false;
    return true;
  }, [
    procedureDate,
    live,
    type,
    showType,
    role,
    showRole,
    aaa,
    showAAA,
    supervisor,
    showSupervisor,
    simulationParts,
    showSimulation,
    assistantParts,
    showAssistantPart,
  ]);

  useEffect(() => {
    setIsValid(validate());
  }, [validate]);

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const toggleLive = useCallback(() => {
    if (live === "live") {
      setShowType(true);
      setShowRole(true);
      setShowSimulation(false);
      setSimulationParts([]);
    }

    if (live === "simulation") {
      setShowType(false);
      setType("");
      setShowRole(false);
      setRole("");
      setShowSimulation(true);
    }
  }, [
    live,
    setShowType,
    setShowRole,
    setShowSimulation,
    setSimulationParts,
    setType,
    setRole,
  ]);

  useEffect(() => {
    toggleLive();
  }, [toggleLive, live]);

  const toggleAAA = useCallback(() => {
    setShowAAA(false);
    setAAA("");
    if (type === "type-1" || type === "type-2") {
      setShowAAA(true);
    }
  }, [type, setShowAAA, setAAA]);

  useEffect(() => {
    toggleAAA();
  }, [toggleAAA]);

  const toggleRole = useCallback(() => {
    setShowSupervisor(false);
    setSupervisor("");
    setShowAssistantPart(false);
    setAssistantParts([]);

    if (role === "role-1") {
      setShowSupervisor(true);
    }

    if (role === "role-4") {
      setShowAssistantPart(true);
    }
  }, [
    role,
    setShowSupervisor,
    setSupervisor,
    setShowAssistantPart,
    setAssistantParts,
  ]);

  useEffect(() => {
    toggleRole();
  }, [toggleRole]);

  const handleChangeLive = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLive(event.target.getAttribute("data-key") ?? "");
  };

  const handleChangeType = (
    event: React.SyntheticEvent | null,
    newValue: string | null
  ) => {
    const target = event?.target as HTMLElement;
    setType(target.getAttribute("data-key") ?? "");
  };

  const handleChangeAAA = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAAA(event.target.getAttribute("data-key") ?? "");
  };

  const handleChangeRole = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRole(event.target.getAttribute("data-key") ?? "");
  };

  const handleChangeAssistantPart = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const dataKey = event.target.getAttribute("data-key") ?? "";

    setAssistantParts((prev) =>
      prev.includes(dataKey)
        ? prev.filter((option) => option !== dataKey)
        : [...prev, dataKey]
    );
  };

  const handleChangeSimulation = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const dataKey = event.target.getAttribute("data-key") ?? "";
    setSimulationParts((prev) =>
      prev.includes(dataKey)
        ? prev.filter((option) => option !== dataKey)
        : [...prev, dataKey]
    );
  };

  const handleChangeSupervisor = (
    event: React.SyntheticEvent | null,
    newValue: string | null
  ) => {
    const target = event?.target as HTMLElement;
    setSupervisor(target.getAttribute("data-key") ?? "");
  };

  const handleChangeCommentOwn = (
    event: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    setCommentOwn(event.target.value);
  };

  const handleChangeCommentSupervisor = (
    event: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    setCommentSupervisor(event.target.value);
  };

  if (procedureDataQuery.isLoading) {
    return <Loading />;
  }

  return (
    <Stack spacing={3}>
      <MediSkillDatePicker
        label="Dato for procedure:"
        direction={isSmallScreen ? "column" : "row"}
        onChange={(newValue) => setProcedureDate(newValue)}
        required={procedureDate === null}
        procedureDate={procedureDate}
        disabled={procedureSaved}
      />

      <MediSKillRadioGroup
        label="Er dette indgreb på rigtige patienter eller simulation?"
        groupName="live-simulation"
        handleChange={handleChangeLive}
        selectedValue={live}
        options={optionsLive}
        direction={isSmallScreen ? "column" : "row"}
        required={live === ""}
        disabled={procedureSaved}
      />

      {showSimulation && (
        <MediSkillCheckbox
          label="Vælg simulation:"
          options={optionsSimulation}
          selectedValues={simulationParts}
          handleChange={handleChangeSimulation}
          direction={isSmallScreen ? "column" : "row"}
          required={simulationParts.length === 0}
          disabled={procedureSaved}
        />
      )}

      {showType && (
        <MediSkillSelect
          label="Vælg indgreb:"
          handleChange={handleChangeType}
          options={optionsType}
          direction={isSmallScreen ? "column" : "row"}
          required={type === ""}
          disabled={procedureSaved}
        />
      )}

      {showAAA && (
        <MediSKillRadioGroup
          label="Var dette et rumperet AAA?"
          groupName="aaa"
          handleChange={handleChangeAAA}
          selectedValue={aaa}
          options={optionsAAA}
          direction={isSmallScreen ? "column" : "row"}
          required={aaa === ""}
          disabled={procedureSaved}
        />
      )}

      {showRole && (
        <MediSKillRadioGroup
          label="Rolletype"
          groupName="role"
          handleChange={handleChangeRole}
          selectedValue={role}
          options={optionsRole}
          direction={isSmallScreen ? "column" : "row"}
          required={role === ""}
          disabled={procedureSaved}
        />
      )}

      {showAssistantPart && (
        <MediSkillCheckbox
          label="Angiv din del af indgrebet"
          options={optionsAssistantPart}
          selectedValues={assistantParts}
          handleChange={handleChangeAssistantPart}
          direction={isSmallScreen ? "column" : "row"}
          required={assistantParts.length === 0}
          disabled={procedureSaved}
        />
      )}

      {showSupervisor && (
        <MediSkillSelect
          label="Hvem superviserede dig?"
          handleChange={handleChangeSupervisor}
          options={optionsSupervisor}
          direction={isSmallScreen ? "column" : "row"}
          required={supervisor === ""}
          disabled={procedureSaved}
        />
      )}

      <MediSkillTextarea
        label="Egne bemærkninger til operationen (vises kun til dig)"
        handleChange={handleChangeCommentOwn}
        disabled={procedureSaved}
      />

      {showSupervisor && (
        <MediSkillTextarea
          label="Bemærkninger til supervisor om operationen (vises til dig og supervisor)"
          handleChange={handleChangeCommentSupervisor}
          disabled={procedureSaved}
        />
      )}

      {procedureSaved && (
        <>
          <MutationSuccess message="Procedure gemt" />
          <MediSkillCloseButton closeAction={closeAction} />
        </>
      )}

      {saveProcedureMutation.error && (
        <MutationError message={saveProcedureMutation.error.message} />
      )}

      {!procedureSaved && (
        <MediSkillSubmitButton
          label="Gem procedure"
          handleSubmit={handleSubmit}
          disabled={!isValid}
          loading={saveProcedureMutation.isPending}
        />
      )}
    </Stack>
  );
}
