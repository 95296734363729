import React, { useState, useCallback, useMemo } from "react";
import {
  Table,
  IconButton,
  useTheme,
  CircularProgress,
  Typography,
  Box,
} from "@mui/joy";
import { Link } from "react-router-dom";
import MenuOpenSharp from "@mui/icons-material/MenuOpenSharp";
import { EnhancedTableToolbar } from "./EnhancedToolbar";
import {
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
} from "@mui/material";
import { Loading } from "./Loading";
import { Error } from "./Error";

/**
 * GenericTableProps interface defines the properties required to render the GenericTable component.
 *
 * @template TData - Type of the data passed to the table.
 * @template TEntity - Type of the entities contained in the data.
 */
interface GenericTableProps<TData, TEntity> {
  /**
   * The data to be displayed in the table. If null, indicates no data is available.
   */
  data: TData | null;

  /**
   * Boolean indicating whether the data is currently being loaded.
   */
  loading: boolean;

  /**
   * An error object containing error details if there is an error in loading data.
   */
  error: Error | null;

  /**
   * A function that extracts an array of entities from the provided data.
   */
  itemsSelector: (data: TData) => TEntity[];

  /**
   * An array of column definitions specifying the columns to be displayed in the table.
   */
  columns: Array<{
    /**
     * Unique identifier for the column.
     */
    id: string;
    /**
     * Display label for the column.
     */
    label: string;
    /**
     * Function to render the content of a cell for this column, given a row entity.
     */
    output: (row: TEntity) => any;
    /**
     * Optional styling object for the column.
     */
    sx?: object;
  }>;

  /**
   * Function to extract a unique identifier for a given entity.
   */
  idSelector: (entity: TEntity) => string;

  /**
   * Optional title to be displayed above the table.
   */
  title?: string;

  /**
   * Optional function to render a component for creating a new entity.
   */
  createComponent?: (
    createAction: boolean,
    setCreateAction: React.Dispatch<React.SetStateAction<boolean>>
  ) => JSX.Element;

  /**
   * Optional function to render a component for updating an existing entity.
   */
  updateComponent?: (
    updateAction: boolean,
    deselectUpdate: (value: boolean) => void,
    selected: TEntity
  ) => JSX.Element;

  /**
   * Optional function to generate a URL for a given entity, used for linking rows.
   */
  openUrl?: (entity: TEntity) => string;
}

type Order = "asc" | "desc";

/**
 * GenericTable component renders a table with configurable columns, sorting, and optional create/update components.
 *
 * @template TEntity - Type of the entities contained in the data.
 * @template TData - Type of the data passed to the table.
 */
export function GenericTable<TEntity, TData>({
  data,
  loading,
  error,
  itemsSelector,
  columns,
  idSelector,
  title,
  createComponent,
  updateComponent,
  openUrl,
}: GenericTableProps<TData, TEntity>) {
  const [selected, setSelected] = useState<{
    selectedId: string | undefined;
    selected: TEntity | undefined;
  }>({
    selectedId: undefined,
    selected: undefined,
  });

  const theme = useTheme();
  const [createAction, setCreateAction] = useState<boolean>(false);
  const [updateAction, setUpdateAction] = useState<boolean>(false);

  const handleClick = (entity: TEntity, id: string) => {
    if (selected.selectedId === id) {
      setSelected({ selected: undefined, selectedId: undefined });
      setUpdateAction(false);
    } else {
      setSelected({ selectedId: id, selected: entity });
      setUpdateAction(true);
    }
  };

  const deselectUpdate = useCallback((value: boolean) => {
    setUpdateAction(value);
    if (!value) {
      setSelected({ selected: undefined, selectedId: undefined });
    }
  }, []);

  const [order, setOrder] = useState<Order>("asc");
  const [orderBy, setOrderBy] = useState<keyof TEntity | null>(null);

  const handleRequestSort = (property: keyof TEntity) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const rows = useMemo(() => {
    const items = data ? itemsSelector(data) : [];
    if (!orderBy) return items;

    return items.sort((a, b) => {
      const aValue = a[orderBy];
      const bValue = b[orderBy];

      if (typeof aValue === 'string' && typeof bValue === 'string') {
        // Case-insensitive comparison
        const aLower = aValue.toLowerCase();
        const bLower = bValue.toLowerCase();

        if (aLower < bLower) return order === "asc" ? -1 : 1;
        if (aLower > bLower) return order === "asc" ? 1 : -1;
        return 0;
      }

      if (aValue < bValue) return order === "asc" ? -1 : 1;
      if (aValue > bValue) return order === "asc" ? 1 : -1;
      return 0;
    });
  }, [data, itemsSelector, order, orderBy]);

  if (loading) {
    return <Loading />;
  }

  if (error) {
    return <Error message={error.message} />;
  }

  return (
    <>
      <EnhancedTableToolbar
        title={title ?? ""}
        createAction={createComponent ? () => setCreateAction(true) : undefined}
      />
      <Table
        sx={{
          "--TableCell-selectedBackground": (theme) =>
            theme.vars.palette.success.softBg,
        }}
        hoverRow={true}
      >
        <TableHead>
          <TableRow>
            {columns.map((column) => (
              <TableCell
                key={column.id}
                sx={{
                  backgroundColor: `${theme.palette.primary[700]} !important`,
                  ...column.sx,
                }}
              >
                {column.label}
                <TableSortLabel
                  active={orderBy === column.id}
                  direction={orderBy === column.id ? order : "asc"}
                  onClick={() => handleRequestSort(column.id as keyof TEntity)}
                  sx={{
                    "&.Mui-active": {
                      color: theme.palette.text.secondary,
                    },
                    "&.MuiTableSortLabel-root": {
                      fontSize: 18,
                    },
                    "&:hover": {
                      color: theme.palette.text.secondary,
                    },
                    "& .MuiTableSortLabel-icon": {
                      color: `${theme.palette.text.secondary} !important`,
                      "&:hover": {
                        color: `${theme.palette.background} !important`,
                      },
                    },
                  }}
                ></TableSortLabel>
              </TableCell>
            ))}
            {openUrl && <TableCell style={{ width: "50px" }}></TableCell>}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, index) => {
            const rowId = idSelector(row);
            return (
              <TableRow
                key={rowId}
                onClick={() => handleClick(row, rowId)}
                sx={{
                  backgroundColor:
                    index % 2 === 0 ? "inherit" : theme.palette.primary[50],
                }}
              >
                {columns.map((column) => (
                  <TableCell key={column.id}>{column.output(row)}</TableCell>
                ))}
                {openUrl && (
                  <TableCell>
                    <IconButton to={openUrl(row)} component={Link}>
                      <MenuOpenSharp />
                    </IconButton>
                  </TableCell>
                )}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
      {updateComponent &&
        selected.selected &&
        updateComponent(updateAction, deselectUpdate, selected.selected)}
      {createComponent && createComponent(createAction, setCreateAction)}
    </>
  );
}
