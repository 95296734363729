import { useKindeAuth } from "@kinde-oss/kinde-auth-react";
import NotFound from "../Pages/NotFound";
import { SupervisorView } from "../Supervisor/SupervisorView";

export default function SupervisorRoute() {
  const { getPermission } = useKindeAuth();

  if (
    getPermission("procedure:view-others").isGranted &&
    getPermission("procedure:assess").isGranted
  ) {
    return <SupervisorView />;
  } else {
    return <NotFound />;
  }
}
