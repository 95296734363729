import { useQuery } from "@tanstack/react-query";
import { ApiService } from "./ApiService";
import { useAuth } from "./AuthProvider";

interface AssessmentData {
  heading: string;
  guideText: string;
  questions: {
    [key: string]: Question;
  };
}

interface Question {
  heading: string;
  type: string;
  min: number;
  max: number;
  order: number;
  labels: {
    [key: number]: string;
  };
}

export const useFetchAssessmentDataQuery = (type: string) => {
  const { token } = useAuth();
  const apiService = new ApiService(token);

  return useQuery({
    queryKey: [type],
    queryFn: async () => {
      return await apiService.makeRequest<AssessmentData>(
        "/api/v1/assessment/data/" + type
      );
    },
    enabled: token !== "",
  });
};
