import { Card, Typography, Box } from "@mui/joy";

// Base interface for common properties
interface MockProps {
  maxWidth?: number | string;
  maxHeight?: number | string;
}

// Interface for MockImage props
interface MockImageProps extends MockProps {
  src: string | string;
  alt?: string | string;
}

// Interface for MockText props
interface MockTextProps extends MockProps {
  text: string;
  fontSize?: number;
}
interface MockContainerProps {
  children: React.ReactNode;
}
export function MockContainer({ children }: MockContainerProps) {
  return (
    <Box
      sx={{
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "left",
        gap: 2,
      }}
    >
      {children}
    </Box>
  );
}

export function MockComponent(props: React.PropsWithChildren<MockProps>) {
  const { maxWidth = 400, maxHeight = "auto", children } = props;

  return (
    <Card
      sx={{
        maxHeight: { maxHeight },
        maxWidth: { maxWidth },
        alignContent: "center",
        justifyContent: "center",
      }}
    >
      {children}
    </Card>
  );
}

export function MockImage(props: MockImageProps) {
  const { src, alt = "", maxWidth, maxHeight } = props;

  return (
    <MockComponent maxWidth={maxWidth} maxHeight={maxHeight}>
      <img src={src} alt={alt} />
    </MockComponent>
  );
}

export function MockText(props: MockTextProps) {
  const { text, maxWidth, maxHeight, fontSize } = props;

  return (
    <MockComponent maxWidth={maxWidth} maxHeight={maxHeight}>
      <Typography>{text}</Typography>
    </MockComponent>
  );
}
