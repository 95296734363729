import { Box, useTheme } from "@mui/joy";
import { CheckCircle } from "@mui/icons-material";

/**
 * Props for the MutationSuccess component.
 */
interface MutationSuccessProps {
  /**
   * The success message to be displayed.
   */
  message: string;
}

/**
 * Component for displaying a success message after a mutation action.
 *
 * @param props - Props for the MutationSuccess component.
 * @returns A component for displaying the success message.
 */
export function MutationSuccess({ message }: MutationSuccessProps) {
  const theme = useTheme();
  return (
    <Box
      sx={{
        margin: "20px 5px 20px",
        display: "flex",
        justifyContent: "center",
        color: theme.palette.success[600],
        fontWeight: theme.fontWeight.md,
      }}
    >
      <>{message}</>
      <CheckCircle sx={{ marginLeft: "5px" }} />
    </Box>
  );
}
