import { Stack, Typography, useTheme } from "@mui/joy";
import { useFetchAssessmentDataQuery } from "../../services/AssessmentService";
import { Loading } from "../Loading";
import {
  InputOptions,
  MediSKillRadioGroup,
  MediSkillSlider,
  MediSkillSubmitButton,
  transformResponseToInputOptions,
} from "../NewProcedure/MediSkillComponents";
import { useEffect, useState } from "react";

export function AssessmentOSATS() {
  const assessmentDataQuery = useFetchAssessmentDataQuery("assessment_osats");
  const [assessmentSaved, setAssessmentSaved] = useState<boolean>(false);
  const [isValid, setIsValid] = useState<boolean>(false);
  const theme = useTheme();

  const [questionScores, setQuestionScores] = useState<{
    [key: string]: string | number;
  }>({});
  const [options, setOptions] = useState<{ [key: string]: InputOptions[] }>({});

  useEffect(() => {
    if (assessmentDataQuery.data) {
      const optionsMap: { [key: string]: InputOptions[] } = {};
      for (const questionKey in assessmentDataQuery.data.questions) {
        const question = assessmentDataQuery.data.questions[questionKey];
        if (question.type === "radio") {
          optionsMap[questionKey] = transformResponseToInputOptions(
            question.labels
          );
        }
        if (question.type === "slider") {
          questionScores[questionKey] = (question.min + question.max) / 2;
        }
      }
      setOptions(optionsMap);
    }
  }, [assessmentDataQuery.data]);

  useEffect(() => {
    if (assessmentDataQuery.data) {
      const allAnswered = Object.keys(assessmentDataQuery.data.questions).every(
        (questionKey) =>
          questionScores.hasOwnProperty(questionKey) &&
          questionScores[questionKey] !== ""
      );
      setIsValid(allAnswered);
    }
  }, [questionScores, assessmentDataQuery.data]);

  const handleRadioChange =
    (questionKey: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
      setQuestionScores((prev) => ({
        ...prev,
        [questionKey]: event.target.getAttribute("data-key") ?? "",
      }));
    };

  const handleSliderChange =
    (questionKey: string) => (_: Event, newValue: number | number[]) => {
      setQuestionScores((prev) => ({
        ...prev,
        [questionKey]: Array.isArray(newValue) ? newValue[0] : newValue, // Ensure single value
      }));
    };

  if (assessmentDataQuery.isLoading) {
    return <Loading />;
  }

  console.log(questionScores);
  return (
    <Stack spacing={2}>
      <Typography level="h4">{assessmentDataQuery.data?.heading}</Typography>
      <Typography level="title-md">
        Guide til vurdering: {assessmentDataQuery.data?.guideText}
      </Typography>
      {assessmentDataQuery.data &&
        Object.keys(assessmentDataQuery.data.questions).map((questionKey) => {
          const question = assessmentDataQuery.data.questions[questionKey];

          if (question.type === "radio") {
            return (
              <MediSKillRadioGroup
                key={questionKey}
                label={question.heading}
                groupName={`question ${question.order}`}
                handleChange={handleRadioChange(questionKey)}
                selectedValue={String(questionScores[questionKey] ?? "")}
                options={options[questionKey] || []}
                direction={"column"}
                required={!questionScores.hasOwnProperty(questionKey)}
                disabled={assessmentSaved}
              />
            );
          }
          if (question.type === "slider") {
            return (
              <MediSkillSlider
                handleChange={handleSliderChange(questionKey)}
                label={question.heading}
                labelMin={question.labels[0]}
                labelMid={question.labels[50]}
                labelMax={question.labels[100]}
                min={Number(question.min)}
                max={Number(question.max)}
                selectedValue={Number(questionScores[questionKey])}
                disabled={assessmentSaved}
              />
            );
          } else {
            return null; // Handle unknown question types
          }
        })}
      <MediSkillSubmitButton
        handleSubmit={() => {}}
        label="Gem bedømmelse"
        disabled={!isValid}
      />
    </Stack>
  );
}
