import { ProcedureIcon } from "./ProcedureIcon";
import miniCexIcon from "../../assets/images/icons/minicex_icon.png";
import { useState } from "react";
import { ModalWrapper } from "../ModalWrapper";
import { MiniCexProcedure } from "../NewProcedure/MiniCexProcedure";

interface MiniCexIconProps {
  size?: number;
}

export function MiniCexIcon(props: MiniCexIconProps) {
  const { size } = props;
  const [open, setOpen] = useState(false);

  const handleIconClick = () => {
    setOpen(true);
  };

  return (
    <>
      <ProcedureIcon src={miniCexIcon} size={size} onClick={handleIconClick} />

      {open && (
        <ModalWrapper
          modalTitle="Tilføj Mini clinical evaluation exercise (mini-CEX)"
          open={open}
          setOpen={setOpen}
          children={(close) => <MiniCexProcedure closeAction={close} />}
        />
      )}
    </>
  );
}
