import { IconButton, Toolbar, Tooltip } from "@mui/material";
import { alpha } from "@mui/material/styles";
import DeleteIcon from "@mui/icons-material/Delete";
import { Box, Typography } from "@mui/joy";
import Button from "@mui/joy/Button";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { inherits } from "util";

export interface EnhancedTableToolbarProps {
  title: string;
  numSelected?: number | undefined;
  deleteAction?: () => void;
  createAction?: () => void;
}

export function EnhancedTableToolbar(props: EnhancedTableToolbarProps) {
  const { numSelected, createAction } = props;

  return (
    <Toolbar
      sx={{
        pl: { xs: 1, sm: 1 },
        pr: { xs: 1, sm: 1 },
        minHeight: "54px !important",
        ...(numSelected &&
          numSelected > 0 && {
            bgcolor: (theme) =>
              alpha(
                theme.palette.primary.main,
                theme.palette.action.activatedOpacity
              ),
          }),
      }}
    >
      {numSelected && numSelected > 0 ? (
        <Typography sx={{ flex: "1 1 100%" }} level="body-md" component="div">
          {numSelected} selected
        </Typography>
      ) : (
        <Typography
          sx={{ flex: "1 1 100%" }}
          level="title-lg"
          id="tableTitle"
          component="div"
        >
          {props.title}
        </Typography>
      )}
      {numSelected && numSelected > 0 ? (
        <Box>
          {props.deleteAction && (
            <Tooltip title="Delete">
              <IconButton>
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          )}
        </Box>
      ) : (
        <Box></Box>
      )}
      {createAction && (
        <Button
          onClick={() => createAction()}
          sx={{
            background: (theme) => theme.palette.primary[700],
            padding: 0.25,
            borderRadius: "50%",
            "& svg": {
              fontSize: "2rem",
            },
          }}
        >
          <AddCircleIcon />
        </Button>
      )}
    </Toolbar>
  );
}
