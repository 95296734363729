import { useCallback, useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { useTheme } from "@mui/joy";
import {
  Education,
  EducationTypes,
  translateEducationKeyToValue,
} from "../../services/EducationService";

interface GanttChartProps {
  educations: Education[] | null;

  educationTypes: EducationTypes;
}

export function GanttChart(props: GanttChartProps) {
  const { educations, educationTypes } = props;
  const theme = useTheme();
  const [series, setSeries] = useState<Array<any> | null>(null);

  const updateSeries = useCallback(() => {
    const series = [
      {
        data: [] as {
          x: string;
          y: [number, number];
          fillColor: string;
        }[],
      },
    ];

    educations?.forEach((education: Education) => {
      const end_date = education.end_date ?? new Date();
      series[0].data.push({
        x: translateEducationKeyToValue(educationTypes ?? [], education.type),
        y: [education.start_date.getTime(), end_date.getTime()],
        fillColor: theme.palette.primary[700],
      });
    });

    setSeries(series);
  }, [educations, educationTypes, theme]);

  useEffect(() => {
    updateSeries();
  }, [educations, updateSeries]);

  const options = {
    chart: {
      type: "rangeBar" as const,
      zoom: {
        enabled: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: true,
        isDumbbell: true,
      },
    },

    stroke: {
      width: 3,
      colors: [theme.palette.primary[700]],
    },
    xaxis: {
      type: "datetime" as const,
      labels: {
        format: "dd/MM-yy",
      },
    },
    yaxis: {
      show: true,
    },
    grid: {
      row: {
        colors: [theme.palette.primary[50], theme.palette.primary[100]],
        opacity: 1,
      },
    },
    fill: {
      type: "gradient",
      gradient: {
        shade: "light",
        type: "horizontal",
        shadeIntensity: 0.5,
      },
    },
    tooltip: {
      enabled: true,
      shared: false,
      y: {
        formatter: function (val: number, opts?: any): string {
          //For some reason, it always runs last where opts is undefined
          return val.toString();
        },
      },
    },
  };

  return (
    <>
      {series && (
        <div>
          <div id="chart">
            <ReactApexChart
              options={options}
              series={series}
              type="rangeBar"
              height={65 * series[0].data.length}
            />
          </div>
        </div>
      )}
    </>
  );
}
