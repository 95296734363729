import NotFound from "../Pages/NotFound";
import { useKindeAuth } from "@kinde-oss/kinde-auth-react";
import { Card, Stack, Box } from "@mui/joy";
import { GenericTable } from "../GenericTable";
import { UserSummary } from "./UserSummary";
import PersonIcon from "@mui/icons-material/Person";
import { ModalWrapper } from "../ModalWrapper";
import { UserMutate } from "./UserMutate";
import {
  UserData,
  OrganizationDetails,
  User,
  useFetchusersQuery,
} from "../../services/UserService";
import { UserCreate } from "./UserCreate";
import UserStatsCard from "./UserStatCard";
import {
  useFetchProcedureCountQuery,
  useFetchUserCountsQuery,
} from "../../services/StatisticService";
import { ProcedureStatCard } from "./ProcedureStatCard";

const UserAdmin = () => {
  const { getPermission } = useKindeAuth();
  const queryUsers = useFetchusersQuery();

  const procedureCountQuery = useFetchProcedureCountQuery();
  const userCountQuery = useFetchUserCountsQuery();

  if (
    getPermission("user:view").isGranted ||
    getPermission("user:edit").isGranted ||
    getPermission("user:create").isGranted ||
    getPermission("user:delete").isGranted
  ) {
    //Format columns for the table
    const columns = [
      {
        id: "picture",
        label: "",
        output: (row: User) => (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            {row.picture !== null && row.picture !== "" ? (
              <img
                src={row.picture}
                style={{
                  width: "40px",
                }}
                alt=""
              />
            ) : (
              <PersonIcon fontSize="large" />
            )}
          </Box>
        ),
        sx: {
          width: "100px",
        },
      },
      {
        id: "full_name",
        label: "Navn",
        output: (row: User) => row.full_name,
      },
      {
        id: "email",
        label: "Email",
        output: (row: User) => row.email,
      },
      {
        id: "roles",
        label: "Roller",
        output: (row: User) => {
          return row.roles
            .sort((a, b) => a.name.localeCompare(b.name))
            .map((role) => role.name)
            .join(", ");
        },
      },
    ];

    return (
      <Stack spacing={2}>
        <Box sx={{ display: "flex", gap: 2 }}>
          <Card sx={{ maxWidth: "400px" }}>
            <UserSummary
              users={queryUsers.data?.organization_users}
              title={`Brugere i ${queryUsers.data?.organization_details.name}`}
              loading={queryUsers.isLoading}
              error={queryUsers.error}
            />
          </Card>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              gap: 2,
            }}
          >
            <ProcedureStatCard
              proceduresCurrentMonth={
                procedureCountQuery.data?.current_month_count
              }
              proceduresPreviousMonth={
                procedureCountQuery.data?.previous_month_count
              }
              isLoading={procedureCountQuery.isLoading}
            />

            <UserStatsCard activeUsers={15} percentageChange={42} />

            <UserStatsCard activeUsers={12} percentageChange={-32} />

            <UserStatsCard activeUsers={15} percentageChange={42} />
          </Box>
        </Box>
        <Card>
          <GenericTable
            data={queryUsers.data ?? null}
            loading={queryUsers.isLoading}
            error={queryUsers.error}
            columns={columns}
            itemsSelector={(queryUsers: UserData) =>
              queryUsers.organization_users
            }
            idSelector={(row: User) => row.id}
            title="Alle brugere"
            createComponent={(open, setOpen) => {
              return (
                <ModalWrapper
                  modalTitle="Tilføj bruger"
                  open={open}
                  setOpen={setOpen}
                  children={(close) => <UserCreate closeAction={close} />}
                />
              );
            }}
            updateComponent={(open, setOpen, entity) => {
              return (
                <ModalWrapper
                  modalTitle={entity.full_name}
                  open={open}
                  setOpen={setOpen}
                  children={(close) => (
                    <UserMutate
                      user={entity as User}
                      organizationDetails={
                        queryUsers.data
                          ?.organization_details as OrganizationDetails
                      }
                      closeAction={close}
                    />
                  )}
                />
              );
            }}
          />
        </Card>
      </Stack>
    );
  }

  return <NotFound />;
};

export default UserAdmin;
