import { Box, Divider, Typography, useTheme } from "@mui/joy";
import { ReactNode } from "react";

interface LabeledSectionProps {
  label: string;
  component: ReactNode;
  direction?: "row" | "column";
  showRequired?: boolean;
  showDivider?: boolean;
}

export function LabeledSection(props: LabeledSectionProps) {
  const {
    label,
    component,
    direction = "row",
    showRequired = false,
    showDivider = true,
  } = props;
  const theme = useTheme();
  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: direction,
          width: "100%",
        }}
      >
        <Box sx={{ width: direction === "column" ? "100%" : "50%" }}>
          <Typography level="h4" fontSize="md" sx={{ paddingBottom: 0.5 }}>
            {label}
          </Typography>
          {showRequired && (
            <Typography level="body-xs" color="warning">
              *Påkrævet
            </Typography>
          )}
        </Box>
        <Box sx={{ width: direction === "column" ? "100%" : "50%" }}>
          {component}
        </Box>
      </Box>
      {showDivider && <Divider sx={{ color: theme.palette.primary[700] }} />}
    </>
  );
}

export default LabeledSection;
