import { Box, CircularProgress, Typography } from "@mui/joy";

export function Loading() {
  return (
    <Box display="flex" alignItems="center">
      <Typography level="title-md" padding={0.5}>
        Loading
      </Typography>
      <CircularProgress sx={{ marginLeft: 2 }} size="sm" />
    </Box>
  );
}
