import Button from "@mui/joy/Button";
import { Box, Typography, useTheme } from "@mui/joy";
import { DangerousOutlined } from "@mui/icons-material";
import { useEffect, useState } from "react";
import {
  OrganizationDetails,
  User,
  useRemoveUserFromOrgMutation,
} from "../../services/UserService";
import { MutationError } from "../MutateError";
import { MutationSuccess } from "../MutateSuccess";

/**
 * Props for the UserDelete component.
 */
interface UserDeleteProps {
  organizationDetails: OrganizationDetails;

  user: User;

  isLoading: boolean;

  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

export function UserDelete(props: UserDeleteProps) {
  const { user, organizationDetails, isLoading, setLoading } = props;
  const [checkDelete, setCheckDelete] = useState<boolean>(false);
  const [isDeleted, setIsDeleted] = useState<boolean>(false);

  const removeUserMutation = useRemoveUserFromOrgMutation({
    onSuccessCallback: () => {
      setIsDeleted(true);
    },
  });

  useEffect(() => {
    setLoading(removeUserMutation.isPending);
  }, [removeUserMutation.isPending, setLoading]);

  const handleDelete = async function () {
    removeUserMutation.mutate({ userId: user.id });
  };

  const theme = useTheme();
  return (
    <>
      {!checkDelete &&
        !isDeleted && ( //Show option to delete
          <Box sx={{ display: "flex" }}>
            <Button
              variant="solid"
              color="danger"
              sx={{
                textTransform: "none",
                width: "100%",
                backgroundColor: theme.palette.danger[700],
                "&:hover": {
                  backgroundColor: theme.palette.danger[800],
                },
              }}
              onClick={() => {
                setCheckDelete(true);
              }}
              disabled={isLoading}
            >
              Fjern {user.full_name} fra {organizationDetails.name}
            </Button>
          </Box>
        )}

      {
        //Show any errors in deleting
        removeUserMutation.error && (
          <MutationError message={removeUserMutation.error.message} />
        )
      }

      {checkDelete && !isDeleted && (
        <>
          <Box
            sx={{
              margin: "20px 0px 0px",
              color: theme.palette.danger[700],
              display: "flex",
              alignItems: "center",
            }}
          >
            <DangerousOutlined sx={{ fontSize: "2em", marginLeft: "10px" }} />
            <Typography
              level="body-md"
              textColor="danger.700"
              sx={{ marginLeft: "30px" }}
            >
              Er du sikker på du vil fjerne {user.full_name}?
            </Typography>
          </Box>

          <Box sx={{ display: "flex", flexDirection: "rows", gap: 3 }}>
            <Button
              role="submit"
              disabled={removeUserMutation.isPending}
              onClick={() => {
                setCheckDelete(false);
              }}
              sx={{
                margin: "20px 0px",
                width: "100%",
                backgroundColor: theme.palette.primary[700],
                "&:hover": {
                  backgroundColor: theme.palette.primary[800],
                },
              }}
            >
              Nej
            </Button>
            <Button
              role="submit"
              disabled={removeUserMutation.isPending}
              loading={removeUserMutation.isPending}
              onClick={handleDelete}
              sx={{
                margin: "20px 0px",
                width: "100%",
                backgroundColor: theme.palette.danger[700],
                "&:hover": {
                  backgroundColor: theme.palette.danger[800],
                },
              }}
            >
              Ja
            </Button>
          </Box>
        </>
      )}

      {isDeleted && <MutationSuccess message={`${user.full_name} fjernet`} />}
    </>
  );
}
